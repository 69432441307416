import { useQueries } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useState } from "react";
import { getFellowshipTypes, getFormFields } from "src/api";
import { getFromStorage, setToStorage } from "src/utilities/functions";

const AppContext = createContext({
	user: {},
	setUser: () => {},
	token: "",
	setToken: () => {},
	fellowshipTypes: null,
	formFields: null,
});

const AppContextProvider = ({ children }) => {
	const [user, setUser] = useState(getFromStorage("user") || {});
	const [token, setToken] = useState(getFromStorage("token") || "");

	useEffect(() => {
		setToStorage("user", user);
	}, [user]);

	useEffect(() => {
		setToStorage("token", token);
	}, [token]);

	const [{ data: rawTypes }, { data: rawFormFields }] = useQueries({
		queries: [
			{ queryKey: ["fellowship-types"], queryFn: getFellowshipTypes, suspense: true, refetchOnWindowFocus: false },
			{ queryKey: ["form-fields"], queryFn: getFormFields, suspense: true, refetchOnWindowFocus: false },
		],
	});

	const fellowshipTypes = rawTypes?.data;
	const formFields = rawFormFields?.data;

	return <AppContext.Provider value={{ user, setUser, token, setToken, formFields, fellowshipTypes }}>{children}</AppContext.Provider>;
};

export default AppContextProvider;

export const useAppContext = () => useContext(AppContext);
