import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getSubmittedProposals } from "src/api";
import { AttachmentIcon, ClockIcon, PaymentIcon, PriceTagIcon, UserCheckIcon } from "src/assets/svg";
import { InfoCard } from "src/pages/FellowshipDetails";
import { extractFileName, formatStringDate, renderCurrency } from "src/utilities/functions";
import { sanitizeHtmlOption } from "src/utilities/constants";
import sanitizeHtml from "sanitize-html";
import { format } from "date-fns";

const MyProposals = ({ fellowship }) => {
	const { data } = useQuery({
		queryKey: ["my-proposal", fellowship?.uuid],
		queryFn: () => getSubmittedProposals(fellowship?.uuid),
		suspense: true,
	});

	const proposal = data?.data;

	return (
		<div>
			<div className="grid min-[950px]:grid-cols-[1fr_max-content] gap-16 border border-[#919BAF66] px-8 py-12 mb-12 rounded-xl">
				<div>
					<h2 className="text-[2.4rem] mb-8 text-headerText font-semibold font-Roobert">Program Details</h2>
					<p className="text-[1.8rem] text-headerText font-medium font-Roobert">{fellowship.title}</p>
					<p className="text-[#778499] text-[1.4rem] mb-8">
						Posted {format(fellowship?.created_at || "2024-06-27T08:45:05.000000Z", "MMM dd, yyyy")}
					</p>
					<div
						className="*:font-medium *:text-[1.4rem] *:text-bodyText2 *:leading-[1.7] truncate-overflow [--max-lines:6] [--line-height:2.38rem] mb-10"
						dangerouslySetInnerHTML={{
							__html: sanitizeHtml(fellowship?.description, sanitizeHtmlOption),
						}}
					/>
					<p className="text-[1.8rem] text-headerText font-medium font-Roobert mb-6">Skills and Expertise</p>
					<div className="flex gap-6 max-w-[550px] flex-wrap">
						{fellowship?.skills_required?.map((skill, index) => (
							<p key={index} className="text-[1.4rem] rounded-full font-medium text-bodyText px-6 py-4 bg-[#EFEFEF]">
								{skill}
							</p>
						))}
					</div>
				</div>
				<div className="min-[950px]:w-[clamp(200px,40vw,230px)] grid gap-10 max-[950px]:grid-cols-[repeat(auto-fill,minmax(150px,1fr))] h-fit">
					<InfoCard
						text={formatStringDate(fellowship?.closing_date || "2024-07-04")}
						icon={<ClockIcon />}
						information="Application Closes"
					/>
					<InfoCard text={renderCurrency(fellowship?.project_budget)} icon={<PriceTagIcon />} information="Budget" />
					<InfoCard text={fellowship?.payment_frequency?.replace("-", " ")} icon={<PaymentIcon />} information="Payment Frequency" />
					<InfoCard text={fellowship?.level_of_expertise} icon={<UserCheckIcon />} information="Expertise" />
				</div>
			</div>
			<div className="flex flex-wrap items-center justify-between mb-12">
				<h3 className="text-[2rem] text-headerText font-semibold font-Roobert">Your Proposal Terms</h3>
				<p className="text-primary text-[1.6rem] font-semibold">Creator's Budget: {renderCurrency(fellowship?.project_budget)}</p>
			</div>
			<div>
				<p className="text-[1.8rem] text-[#101928] font-bold">How do you want to be paid?</p>
				<p className="text-[1.6rem] text-bodyText2 font-medium mb-12 capitalize">{proposal?.payment_frequency} Payment</p>
				{proposal?.payment_frequency === "milestone" ? (
					<>
						<div className="grid gap-8 mb-16">
							{proposal?.milestones?.map((milestone, index) => (
								<Milestone milestone={milestone} index={index} key={index} />
							))}
						</div>
						<div className="flex items-center justify-between py-[2.5rem] border-y border-[#919BAF66]">
							<div>
								<p className="text-[#101928] text-[1.8rem] font-bold">Total:</p>
								<p className="text-[#818B9C] text-[1.4rem]">
									This is the price the program creator will see. It includes all the milestones together.
								</p>
							</div>
							<p className="text-primary font-semibold text-[1.6rem]">{renderCurrency(proposal?.total_amount)}</p>
						</div>
					</>
				) : (
					<p className="text-[#101928] text-[1.6rem] font-semibold">Bid: {renderCurrency(proposal?.bid)}</p>
				)}
				<p className="font-bold text-[1.8rem] text-headerText mt-14 mb-9">Attachments and other Information</p>
				<p className="text-bodyText2 text-[1.6rem]">{proposal.additional_info}</p>
				<div className="mt-10">
					{proposal?.proposals?.map((attachment, index) => (
						<Attachment attachment={attachment} key={index} />
					))}
				</div>
			</div>
		</div>
	);
};

const Attachment = ({ attachment }) => {
	return (
		<a href={attachment} download={true} className="flex items-center gap-4 mb-2 text-[1.25rem] text-primary">
			<AttachmentIcon /> <span>{extractFileName(attachment)}</span>
		</a>
	);
};

const Milestone = ({ milestone, index }) => {
	return (
		<div className="grid grid-cols-[3rem,1fr,1fr,1fr] gap-4 overflow-scroll">
			<p className="text-[1.4rem] text-black font-semibold pt-1">{index + 1}.</p>
			<div>
				<p className="text-black text-[1.6rem] font-semibold">Description</p>
				<p className="text-bodyText text-[1.4rem]">{milestone?.description}</p>
			</div>
			<div>
				<p className="text-black text-[1.6rem] font-semibold">Due</p>
				<p className="text-bodyText text-[1.4rem]">{milestone?.due_date}</p>
			</div>
			<div>
				<p className="text-black text-[1.6rem] font-semibold">Amount</p>
				<p className="text-bodyText text-[1.4rem]">{renderCurrency(milestone?.amount)}</p>
			</div>
		</div>
	);
};

export default MyProposals;
