import { useQuery } from "@tanstack/react-query";
import React, { Suspense, useState } from "react";
import { creatorFellowships } from "src/api";
import { NoFellowshipIcon, SearchIcon } from "src/assets/svg";
import { CustomInput, EmptyState, FellowshipCard, Loader, Modal, Pagination } from "src/components";
import { CreateFellowship } from "src/modals";

const MyFellowships = () => {
	const [search, setSearch] = useState("");

	return (
		<div>
			<div className="flex items-center justify-between">
				<h1 className="dashboard-header">My Programs</h1>
				<div>
					<CustomInput
						icon={<SearchIcon className="text-bodyText" />}
						value={search}
						placeholder="Search something..."
						onChange={(event) => setSearch(event.target.value)}
						innerContainerClassName="bg-[#F7F7F7] !border-0 !rounded-full !min-w-[300px] max-md:!min-w-[200px] max-md:!w-[210px]"
						className="!bg-transparent"
					/>
				</div>
			</div>
			<div className="py-24">
				<Suspense fallback={<Loader />}>
					<RenderArea search={search} />
				</Suspense>
			</div>
		</div>
	);
};

const RenderArea = ({ search }) => {
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

	const { data } = useQuery({
		queryKey: ["creator-fellowships", search],
		queryFn: () => creatorFellowships({ q: search }),
		suspense: true,
	});

	const fellowships = data?.data?.fellowships?.data;
	const count = data?.data?.fellowships?.total;

	if (!fellowships?.length)
		return (
			<EmptyState icon={<NoFellowshipIcon />}>
				<p className="font-Roobert font-medium text-[1.6rem] md:text-[2rem] text-center text-bodyText2">
					You have not created any programs yet.
					<br />
					Please click the button below to create a program
				</p>
				<button className="secondary-btn w-fit text-[1.4rem] py-5" onClick={() => setIsCreateModalOpen(true)}>
					Create a Program
				</button>
				<Modal isOpen={isCreateModalOpen} setIsOpen={setIsCreateModalOpen} title="Create a program">
					<CreateFellowship />
				</Modal>
			</EmptyState>
		);

	return (
		<>
			<div className="grid gap-x-8 gap-y-11 grid-cols-[repeat(auto-fill,minmax(350px,1fr))] mb-20">
				{fellowships?.map((fellowship, index) => (
					<FellowshipCard fellowship={fellowship} key={index} />
				))}
			</div>
			<Pagination rowCount={count} />
		</>
	);
};

export default MyFellowships;
