import React from "react";
import MessagingContextProvider from "src/context/MessagingContext";
import { Messaging as Mess } from "src/components";
import { cn } from "src/utilities/utils";
import { Navigate, useLocation } from "react-router-dom";
import { useAppContext } from "src/context/AppContext";

const Messaging = ({ creator }) => {
	return (
		<div className={cn("grid h-[calc(100vh-10rem)] pb-[5rem] *:h-full lg:divide-x lg:divide-[#E0E0E0] relative lg:grid-cols-[350px,1fr] thin")}>
			<Mess.MessageRoomList creator={creator} />
			<Mess.MessageRoom creator={creator} />
		</div>
	);
};

const MessagingPage = ({ creator }) => {
	const { pathname, search } = useLocation();
	const { user, token } = useAppContext();

	if (!user || !token) return <Navigate to={{ pathname: "/login", search: `redirect=${encodeURIComponent(pathname.concat(search))}` }} />;

	return (
		<MessagingContextProvider>
			<Messaging creator={creator} />
		</MessagingContextProvider>
	);
};

export default MessagingPage;
