import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { getApplicantFellowship, getEngagementDetails, markMilestoneAsInProgress } from "src/api";
import { Modal } from "src/components";
import { useAppContext } from "src/context/AppContext";
import { SubmitMilestone } from "src/modals";
import { renderConfirmDialogue, renderCurrency, renderSuccessMessage } from "src/utilities/functions";

const EngagementActivities = () => {
	const { id: fellowshipUUID } = useParams();
	const { pathname, search } = useLocation();
	const { user, token } = useAppContext();

	const [{ data: rawFellowshipDetails }, { data: rawEngagementDetails }] = useQueries({
		queries: [
			{
				queryKey: ["fellowship", fellowshipUUID],
				queryFn: () => getApplicantFellowship(fellowshipUUID),
				suspense: true,
			},
			{
				queryKey: ["engagement", fellowshipUUID],
				queryFn: () => getEngagementDetails(fellowshipUUID),
				suspense: true,
			},
		],
	});

	const fellowship = rawFellowshipDetails?.data?.fellowship;
	const engagement = rawEngagementDetails?.data;
	const milestones = engagement?.milestones;

	if (!user || !token) return <Navigate to={{ pathname: "/login", search: `redirect=${encodeURIComponent(pathname.concat(search))}` }} />;

	return (
		<div className="max-w-[1200px] mx-auto py-[4rem]">
			<h1 className="font-Roobert font-bold text-[4rem] text-headerText mb-[4rem]">Your Activity ({fellowship?.title})</h1>
			<p className="text-[#001439] text-[2rem] font-Roobert font-semibold mb-4">Milestone Timeline</p>
			{milestones?.map((milestone, index) => (
				<Milestone engagement={engagement} milestone={milestone} index={index} key={index} />
			))}
		</div>
	);
};

const Milestone = ({ milestone, engagement, index }) => {
	const { id: fellowshipUUID } = useParams();
	const queryClient = useQueryClient();

	const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);

	const { mutate, isLoading } = useMutation(markMilestoneAsInProgress, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(
				async () => await queryClient.refetchQueries({ queryKey: ["engagement", fellowshipUUID], type: "all" })
			),
	});

	const startMilestone = () =>
		renderConfirmDialogue().then((result) => {
			if (result)
				mutate({
					fellowship: fellowshipUUID,
					engagement: engagement?.uuid,
					milestone: milestone?.uuid,
				});
		});

	return (
		<>
			<div className="flex timeline relative before:w-[1px] before:h-[calc(100%-30px-10px)] before:bg-[#919BAF66] before:absolute before:top-[calc(35px)] before:left-[14px] gap-6 min-h-[80px]">
				<p className="border text-primary border-primary min-w-[30px] flex items-center justify-center rounded-full text-center h-[30px]">
					{index + 1}
				</p>
				<div className="grid gap-5 pb-14 h-fit">
					<p className={`text-[#101928] text-[1.6rem] ${milestone?.status === "yet to start" && "!text-[#D9DDE4]"}`}>
						{milestone?.description} <span className="font-semibold">(Due: {milestone?.due_date})</span>
					</p>
					<div className="flex items-center gap-6">
						<p className={`text-[#001439] text-[1.8rem] font-semibold ${milestone?.status === "yet to start" && "!text-[#D9DDE4]"}`}>
							{renderCurrency(milestone?.amount)}
						</p>
						<p
							className={`text-[#0485BC] text-[1.4rem] font-Roobert font-medium capitalize ${
								milestone?.status === "yet to start" && "!text-[#D9DDE4]"
							}`}
						>
							{milestone?.status}
						</p>
					</div>
					{milestone?.status === "in progress" && (
						<button className="px-4 py-4 primary-btn w-fit" onClick={() => setIsSubmitModalOpen(true)}>
							Submit Work
						</button>
					)}
					{milestone?.status === "yet to start" && (
						<button className="px-4 py-4 primary-btn w-fit btn-loader" onClick={startMilestone} disabled={isLoading}>
							Start Milestone
						</button>
					)}
					{milestone?.status === "awaiting confirmation" && (
						<button className="px-4 py-4 primary-btn w-fit" onClick={() => setIsSubmitModalOpen(true)}>
							Resubmit Work
						</button>
					)}
				</div>
			</div>
			<Modal isOpen={isSubmitModalOpen} setIsOpen={setIsSubmitModalOpen} title="Submit Milestone">
				<SubmitMilestone engagement={engagement} milestone={milestone} />
			</Modal>
		</>
	);
};

export default EngagementActivities;
