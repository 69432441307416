import { ErrorMessage } from "formik";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon } from "src/assets/svg";

const CustomDatePicker = ({ label, containerClassName, labelClassName, name, ...rest }) => {
	return (
		<div className={`flex flex-col ${containerClassName}`}>
			{label && (
				<label htmlFor={label} className={`mb-4 block font-medium text-[1.4rem] text-gray ${labelClassName}`}>
					{label}
				</label>
			)}
			<div className="flex gap-3 has-[:focus]:border-primary justify-between border border-lightGray rounded-md items-center px-8 py-4">
				<DatePicker
					dateFormat="dd MMMM YYYY"
					showYearDropdown
					showMonthDropdown
					dropdownMode="scroll"
					className={`flex items-center gap-4 border-0 z-[1000000] text-[1.6rem] p-0 has-[:disabled]:bg-[#FBFBFB] min-w-[250px] focus:![--tw-ring-shadow:transparent] !outline-0 placeholder:text-[#a8b0bd] text-bodyText w-full ${rest.className}`}
					{...rest}
				/>
				<CalendarIcon className="text-bodyText2" />
			</div>
			{!!name && <ErrorMessage name={name} component="div" className="block mt-2 text-[1.2rem] text-red-500" />}
		</div>
	);
};

export default CustomDatePicker;
