import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ClockIcon } from "src/assets/svg";
import sanitizeHtml from "sanitize-html";
import avatar from "src/assets/avatar.png";
import { sanitizeHtmlOption } from "src/utilities/constants";
import { formatStringDate } from "src/utilities/functions";

const FellowshipCard = ({ fellowship }) => {
	const { pathname } = useLocation();

	const prefix = pathname.includes("creator") ? "/creator/my-programs" : pathname.includes("judge") ? "/judge" : "/programs";

	const usersArray = Array(fellowship?.total_users).fill("");
	const overflowCount = usersArray.length - 4;

	return (
		<Link to={prefix.concat(`/${fellowship?.uuid}`)} className="px-6 py-6 border rounded-lg grid border-[#eaeaea] relative">
			<p className="font-Roobert font-semibold text-[1.8rem] mb-3">{fellowship?.title}</p>
			<div
				className="*:text-[1.4rem] *:text-bodyText *:font-medium mb-5 leading-[1.5] truncate-overflow [--max-lines:3] [--line-height:2.1rem]"
				dangerouslySetInnerHTML={{
					__html: sanitizeHtml(fellowship?.description, sanitizeHtmlOption),
				}}
			/>
			<div className="flex items-center justify-between mb-5">
				{!!usersArray?.length && (
					<div className="flex items-center">
						{usersArray.slice(0, 4).map((image, index) => (
							<img
								key={index}
								src={avatar}
								alt=""
								className={["w-[35px] aspect-square rounded-full border border-gray-400", index === 0 ? "" : "-ml-5"].join(" ")}
							/>
						))}
						{overflowCount > 0 && (
							<div className="w-[35px] aspect-square rounded-full -ml-5 bg-headerText text-white flex items-center justify-center font-semibold text-[1.4rem]">
								+{overflowCount}
							</div>
						)}
					</div>
				)}
				{!!fellowship?.status ? (
					<span
						className={`text-[1.2rem] font-medium ${
							fellowship?.status === "Pending" ? "bg-[#FFF8EB] text-[#EEA23E]" : "bg-[#F0FAF0] text-[#2D8A39]"
						} px-3 py-2 rounded-full`}
					>
						{fellowship?.status}
					</span>
				) : (
					<p className="flex items-center gap-2 text-[1.4rem] font-medium text-[#0485BC]">
						<ClockIcon /> {formatStringDate(fellowship?.closing_date || "2024-06-24")}
					</p>
				)}
			</div>

			<div className="block w-full p-3 text-center border rounded-full font-semibold text-[1.4rem] text-bodyText2 border-[#EAEBF0]">
				View Program
			</div>
		</Link>
	);
};

export default FellowshipCard;
