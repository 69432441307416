import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppContext } from "src/context/AppContext";

const Index = () => {
	const { user, token } = useAppContext();

	if (!!user && !!token) return <Navigate to="/" />;

	return <Outlet />;
};

export default Index;
