import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./editor.css";
import { ErrorMessage } from "formik";

const CustomEditor = ({ setState, name, state, editorClassName = "editor-class", toolbarClassName = "toolbar-class" }) => {
	return (
		<div>
			<Editor
				editorState={state}
				onEditorStateChange={(editorState) => setState(name, editorState)}
				editorClassName={editorClassName}
				toolbarClassName={toolbarClassName}
			/>
			<ErrorMessage name={name} component="div" className="block mt-2 text-[1.2rem] text-red-500" />
		</div>
	);
};

export default CustomEditor;
