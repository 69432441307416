import sanitizeHtml from "sanitize-html";

export const sanitizeHtmlOption = {
	allowedTags: sanitizeHtml.defaults.allowedTags.concat(["ul", "ol", "img", "ins"]),
	allowedAttributes: {
		a: ["name"],
		img: ["src"],
	},
	disallowedTagsMode: "recursiveEscape",
};

export const validFileExtensions = ["jpg", "jpeg", "png", "csv", "txt", "docx", "doc", "ppt", "pptx", "pdf", "rar", "xls", "xlsx", "zip"];
