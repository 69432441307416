import { useIsMutating, useMutation, useQuery } from "@tanstack/react-query";
import { Formik } from "formik";
import React, { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { getApplicationForm, respondToApplicationForm } from "src/api";
import * as Yup from "yup";
import QuestionInput from "./QuestionInput";
import { renderSuccessMessage } from "src/utilities/functions";
import { useModalContext } from "src/components/Modal";
import { useNavigate } from "react-router-dom";
import { Loader } from "src/components";

const ApplyToFellowship = ({ fellowshipId }) => {
	const { setIsOpen } = useModalContext();
	const navigate = useNavigate();
	const [currentTab, setCurrentTab] = useState(0);

	const { data, isLoading, isError } = useQuery({
		queryKey: ["application-form", fellowshipId],
		queryFn: () => getApplicationForm(fellowshipId),
	});

	const formDetails = data?.data;
	const sections = formDetails?.sections;
	const title = formDetails?.fellowship_title;

	const questions = sections?.flatMap((section) =>
		section.questions.map((question) => ({
			...question,
			form_section_id: section.id,
		}))
	);

	var validationObject = {};
	var initialValues = {};
	questions?.forEach((question) => {
		const key = `${question.form_section_id}||${question.id}||${question.title?.replaceAll(" ", "-")}`;
		initialValues[key] = "";

		var validation = Yup;

		if (question.form_field_type === "checkboxes") {
			validation = validation.array();
		} else {
			validation = validation.string();
		}

		if (
			!!question.options &&
			(question.form_field_type === "dropdown" || question.form_field_type === "multiple_choice") &&
			Array.isArray(question.options)
		) {
			validation = validation.oneOf(question.options);
		}

		if (!!question.options && question.form_field_type === "checkboxes" && Array.isArray(question.options)) {
			validation = validation.of(Yup.string().oneOf(question.options));
		}

		if (question.is_required) {
			validation = validation.required("This field is required");
		}

		validationObject[key] = validation;
	});

	const { mutate } = useMutation(respondToApplicationForm, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(async () => {
				navigate("/my-programs");
				setIsOpen(false);
			}),
		mutationKey: ["form-response"],
	});

	if (isLoading) return <Loader />;

	if (isError)
		return (
			<div>
				<p className="text-bodyText2 text-center text-[2.2rem] font-medium">No application form yet</p>
			</div>
		);

	return (
		<div>
			<h1 className="mb-10 text-headerText text-[3rem] font-Roobert font-semibold">Apply to join the `{title}` Program</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={Yup.object().shape(validationObject)}
				onSubmit={(values) => {
					const data = Object.entries(values).map(([key, value]) => {
						// eslint-disable-next-line no-unused-vars
						const [form_section_id, question_id, questionTitle] = key.split("||");

						return { form_section_id, question_id, response: value };
					});

					mutate({ id: fellowshipId, data });
				}}
			>
				{({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Tabs
							selectedIndex={currentTab}
							onSelect={(tab) => setCurrentTab(tab)}
							className="grid"
							selectedTabClassName="text-primary border-b border-primary rounded-0"
						>
							<TabList className="flex gap-2 overflow-scroll hide-scrollbar mb-14">
								{sections.map((section) => (
									<Tab
										key={section.id}
										className="py-4 min-w-fit px-5 hover:text-primary hover:bg-[#FDF3F0] cursor-pointer font-medium text-[1.5rem] text-bodyText"
									>
										{section.title}
									</Tab>
								))}
							</TabList>
							{sections.map((section, index) => (
								<TabPanel key={section.id}>
									<Section
										section={section}
										isLastPanel={sections.length - 1 === index}
										isFirstPanel={index === 0}
										index={index}
										setCurrentTab={setCurrentTab}
									/>
								</TabPanel>
							))}
						</Tabs>
					</form>
				)}
			</Formik>
		</div>
	);
};

const Section = ({ section, isLastPanel, isFirstPanel, setCurrentTab, index }) => {
	const isLoading = useIsMutating({ mutationKey: ["form-response"] });

	const questions = section?.questions;

	return (
		<div className="grid gap-10">
			{questions.map((question) => (
				<QuestionInput section={section} question={question} key={question.id} />
			))}
			<div className="flex gap-4 ml-auto">
				{!isFirstPanel && (
					<button className="py-5 ml-auto secondary-btn" type="button" onClick={() => setCurrentTab(index - 1)}>
						Back
					</button>
				)}
				<button
					className="py-5 primary-btn btn-loader"
					type={isLastPanel ? "submit" : "button"}
					onClick={isLastPanel ? null : () => setCurrentTab(index + 1)}
					disabled={isLoading}
				>
					{isLastPanel ? "Submit" : "Next"}
				</button>
			</div>
		</div>
	);
};

export default ApplyToFellowship;
