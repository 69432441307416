import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import { gradeApplicant } from "src/api";
import { CustomInput } from "src/components";
import { useModalContext } from "src/components/Modal";
import { renderSuccessMessage } from "src/utilities/functions";
import * as Yup from "yup";

const GradeApplicant = ({ applicant }) => {
	const queryClient = useQueryClient();
	const { id } = useParams();
	const { setIsOpen } = useModalContext();

	const initialValues = {
		fellowship: id,
		applicant_id: applicant?.id,
		grade: 0,
	};
	const validationSchema = Yup.object().shape({
		fellowship: Yup.string().required("Program is required"),
		applicant_id: Yup.number().required("Applicant is required"),
		grade: Yup.number().min(0, "Minimum grade is zero").max(100, "Maximum grade is 100").required("Grade is required"),
	});

	const { mutate, isLoading } = useMutation(gradeApplicant, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(async () => {
				await queryClient.invalidateQueries({ queryKey: ["judge-fellowship-applicants"], type: "active" });
				await queryClient.invalidateQueries({ queryKey: ["judge-fellowship-graded-applicants"], type: "active" });
				setIsOpen(false);
			}),
	});
	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="grid gap-8">
					<div className="">
						<p className="text-headerText leading-[1] overflow-hidden font-semibold truncate text-[1.8rem] mb-1">
							{applicant?.first_name} {applicant?.last_name}
						</p>
						<p className="text-bodyText text-[1.5rem] truncate">{applicant?.email}</p>
					</div>
					<CustomInput type="number" step={0.1} label="Grade applicant" value={values.grade} onChange={handleChange} name="grade" />
					<div className="flex items-center justify-end">
						<button className="cancel-btn" type="button" onClick={() => setIsOpen(false)}>
							Cancel
						</button>
						<button className="px-6 py-4 primary-btn btn-loader" disabled={isLoading}>
							Submit Grade
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default GradeApplicant;
