import React, { useState } from "react";
import CreateFellowshipDetails from "./CreateFellowshipDetails";
import FellowshipQuestions from "./FellowshipQuestions";

const CreateFellowship = () => {
	const [fellowshipId, setFellowshipId] = useState(null);

	return !!fellowshipId ? <FellowshipQuestions fellowshipId={fellowshipId} /> : <CreateFellowshipDetails setFellowshipId={setFellowshipId} />;
};

export default CreateFellowship;
