import { useQuery } from "@tanstack/react-query";
import React, { Suspense, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getFellowships } from "src/api";
import { CheckIcon, DownIcon, NoFellowshipIcon, SearchIcon } from "src/assets/svg";
import { EmptyState, FeaturedCard, Loader, Pagination } from "src/components";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { useAppContext } from "src/context/AppContext";

const Fellowships = () => {
	const { fellowshipTypes } = useAppContext();
	const [searchParams] = useSearchParams();

	const optionArray = [{ name: "All", uuid: "" }, ...fellowshipTypes];
	const paramType = searchParams.get("type") || "";
	const selectedType = optionArray?.find((option) => option.uuid === paramType);

	const [searchText, setSearchText] = useState(searchParams.has("searchText") ? searchParams.get("searchText") : "");
	const [type, setType] = useState(!!selectedType ? selectedType : optionArray?.at(0));

	const options = optionArray?.map((option) => {
		if (option.name === "All")
			return {
				text: <div className="flex items-center gap-4">All</div>,
				value: "",
				onClick: () => setType({ name: "All", uuid: "" }),
			};

		const { name, icon, uuid } = option;
		return {
			text: (
				<div className="flex items-center gap-4">
					<img src={icon} className="w-[2rem] h-[2rem]" alt="" />
					{name}
				</div>
			),
			value: uuid,
			onClick: () => setType(option),
		};
	});

	useEffect(() => {
		const selectedType = optionArray?.find((option) => option.uuid === paramType);
		setType(!!paramType ? selectedType : optionArray?.at(0));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paramType]);

	return (
		<div id="fellowship-page" className="pb-28">
			<div className="[--bleed-color:#F1F4F6] bg-[var(--bleed-color)] full-bleed pt-6 pb-14 md:py-20 grid gap-10 mb-16">
				<h1 className="font-Roobert font-bold text-[2.4rem] md:text-[4rem] text-headerText">All Programs</h1>
				<p className="text-[1.6rem] text-bodyText font-medium max-w-[420px]">
					Explore a vast library of programs, gain valuable skills, and elevate your career and personal development.
				</p>
				<div className="bg-white rounded-full border max-w-[550px] border-white has-[:focus]:border-primary min-w-[250px] flex">
					<div className="flex flex-1 items-center gap-4 rounded-md px-8 py-4 text-[1.6rem]">
						<SearchIcon />
						<input
							value={searchText}
							onChange={(event) => setSearchText(event.target.value)}
							className="!border-0 focus:![--tw-ring-shadow:transparent] !outline-0 placeholder:text-bodyText text-bodyText text-[1.6rem] w-full p-0"
						/>
					</div>
					<Popover>
						<PopoverTrigger asChild>
							<button
								type="button"
								className="flex relative items-center justify-between gap-2 px-6 py-4 capitalize min-w-[130px] max-w-[170px] text-bodyText after:absolute after:h-1/2 after:content-[''] after:left-0 after:bottom-1/2 after:translate-y-1/2 after:w-[1px] after:bg-bodyText"
							>
								{type?.name} <DownIcon className="w-7 h-7 text-bodyText" />
							</button>
						</PopoverTrigger>
						<PopoverContent className="flex flex-col gap-2 py-2">
							{options.map(({ onClick, text, value: optionValue }, index) => {
								const isSelected = type?.uuid === optionValue;
								return (
									<button
										className={[
											"px-10 py-5 flex gap-3 items-center text-left capitalize hover:bg-gray-100 hover:text-primary font-medium font-Roobert text-bodyText",
											isSelected && "!text-primary",
										].join(" ")}
										onClick={onClick}
										key={index}
									>
										{text} {isSelected && <CheckIcon />}
									</button>
								);
							})}
						</PopoverContent>
					</Popover>
				</div>
			</div>
			<Suspense fallback={<Loader />}>
				<RenderArea searchText={searchText} type={type} />
			</Suspense>
		</div>
	);
};

const RenderArea = ({ searchText, type }) => {
	const { data } = useQuery({
		queryKey: ["user-fellowships", searchText, type?.uuid],
		queryFn: () => getFellowships({ per_page: 15, page: 1, type: type?.uuid, q: searchText }),
		suspense: true,
	});

	const fellowships = data?.data?.data;
	const total = data?.data?.total;

	if (!fellowships?.length)
		return (
			<EmptyState icon={<NoFellowshipIcon />}>
				<p className="font-Roobert font-medium text-[1.6rem] md:text-[2rem] text-center text-bodyText2">No programs are available</p>
			</EmptyState>
		);

	return (
		<>
			{(searchText || type?.name !== "All") && (
				<h2 className="text-bodyText2 text-[2rem] font-semibold mb-8">{fellowships?.length} results found</h2>
			)}
			<div className="grid gap-x-8 gap-y-11 grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
				{fellowships?.map((fellowship, index) => (
					<FeaturedCard fellowship={fellowship} key={index} />
				))}
			</div>
			<Pagination rowCount={total} />
		</>
	);
};

export default Fellowships;
