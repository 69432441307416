import React, { useState } from "react";
import { AddIcon, NoUserIcon } from "src/assets/svg";
import { BreadCrumbs, CustomDataTable, EmptyState, Modal } from "src/components";
import { InviteJudge } from "src/modals";
import { useQuery } from "@tanstack/react-query";
import { fellowshipJudges } from "src/api";
import { useParams } from "react-router-dom";

const columns = [
	{
		name: "Name",
		selector: (row) => `${row?.first_name} ${row?.last_name}`,
	},
	{
		name: "Email",
		selector: (row) => row?.email,
	},
	{
		name: "Program",
		selector: (row) => row?.fellowship_name,
		style: { color: "var(--header-text)" },
	},
	{
		name: "Applicants Assigned",
		selector: (row) => row.applicants_assigned,
	},
];

const FellowshipJudges = () => {
	const { id } = useParams();
	const [isInviteJudgeOpen, setIsInviteJudgeOpen] = useState(false);

	const { data } = useQuery({
		queryKey: ["fellowship-judges", id],
		queryFn: () => fellowshipJudges({ fellowship: id }),
		suspense: true,
	});

	const judges = data?.data;

	return (
		<div>
			<BreadCrumbs />
			<div className="flex flex-wrap items-center justify-between mb-10">
				<h1 className="dashboard-header">Judges</h1>
				<button className="px-6 py-4 primary-btn" onClick={() => setIsInviteJudgeOpen(true)}>
					<AddIcon /> Invite a judge
				</button>
			</div>
			<CustomDataTable
				columns={columns}
				data={judges}
				noDataComponent={
					<EmptyState icon={<NoUserIcon />}>
						<p className="font-Roobert font-medium text-[1.6rem] text-center text-bodyText2">You have not invited any judges yet</p>
						<button className="secondary-btn w-fit text-[1.4rem] py-5" onClick={() => setIsInviteJudgeOpen(true)}>
							<AddIcon /> Invite a judge
						</button>
					</EmptyState>
				}
			/>
			<Modal isOpen={isInviteJudgeOpen} setIsOpen={setIsInviteJudgeOpen} title="Invite Judge">
				<InviteJudge selectedFellowship={{ id }} />
			</Modal>
		</div>
	);
};

export default FellowshipJudges;
