import React from "react";
import { useLocation } from "react-router-dom";

const CheckMail = () => {
	const { state } = useLocation();

	const email = state.email;

	return (
		<div className="grid grid-rows-[max-content,1fr] min-h-screen p-14">
			<h1 className="font-Roobert font-bold text-[2.81rem] text-[#1F1D1D] mb-20">Kohort</h1>
			<div className="mx-auto my-auto">
				<h1 className="text-headerText text-[2.6rem] sm:text-[4rem] font-bold leading-[1.1] text-center mb-6">
					Verify your Email to finish Signing Up
				</h1>
				<p className="max-w-[500px] text-bodyText text-[1.4rem] text-center mb-5 mx-auto">We just sent a link to {email}</p>
				<p className="max-w-[500px] text-bodyText text-[1.4rem] text-center mb-20 mx-auto">
					Kohort is an online platform where individuals and organisations can come together to collaborate, apply for opportunities, etc.
				</p>
			</div>
		</div>
	);
};

export default CheckMail;
