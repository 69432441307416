import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import { setMilestoneStatus } from "src/api";
import { AttachmentIcon } from "src/assets/svg";
import { useModalContext } from "src/components/Modal";
import { extractFileName, renderConfirmDialogue, renderSuccessMessage } from "src/utilities/functions";

const ViewMilestoneSubmission = ({ milestone, engagement }) => {
	const { id: fellowshipUUID } = useParams();
	const { setIsOpen } = useModalContext();
	const queryClient = useQueryClient();

	const { mutate, isLoading } = useMutation(setMilestoneStatus, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(async () => {
				await queryClient.refetchQueries({ queryKey: ["fellowship-engaged-applicants", fellowshipUUID], type: "all" });
				setIsOpen(false);
			}),
	});

	const approve = () =>
		renderConfirmDialogue().then((result) => {
			if (result)
				mutate({
					fellowship: fellowshipUUID,
					engagement: engagement?.uuid,
					milestone: milestone?.uuid,
					status: "completed",
				});
		});

	return (
		<div className="grid gap-6">
			<p className="text-[1.6rem] font-Roobert font-semibold">Milestone: {milestone.description}</p>
			{milestone?.content && (
				<div>
					<p className="text-[1.4rem] text-bodyText2 font-medium mb-1">Content</p>
					<p className="text-[1.4rem] text-bodyText">{milestone?.content}</p>
				</div>
			)}
			{milestone?.content && (
				<div>
					<p className="text-[1.4rem] text-bodyText2 font-medium mb-1">Attachment(s)</p>
					<Attachment attachment={milestone?.file_upload} />
				</div>
			)}
			<div className="flex items-center justify-end mt-5">
				<button className="cancel-btn" type="button" onClick={() => setIsOpen(false)}>
					Cancel
				</button>
				<button className="px-6 py-4 primary-btn btn-loader" disabled={isLoading} onClick={approve}>
					Approve
				</button>
			</div>
		</div>
	);
};

const Attachment = ({ attachment }) => {
	return (
		<a href={attachment} download={true} className="flex items-center gap-4 mb-2 text-[1.25rem] text-primary">
			<AttachmentIcon /> <span>{extractFileName(attachment)}</span>
		</a>
	);
};

export default ViewMilestoneSubmission;
