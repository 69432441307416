import React, { Fragment } from "react";
import { Link, useLocation, useMatches } from "react-router-dom";
import { RightArrowHeadIcon } from "src/assets/svg";

const BreadCrumbs = () => {
	const location = useLocation();
	let matches = useMatches();

	const crumbs = matches.filter((match) => Boolean(match.handle?.crumb));

	const length = crumbs?.length;

	return (
		<ol className="flex items-center gap-2 text-bodyText text-[1.4rem] font-medium mb-10">
			{crumbs.map((crumb, index) => (
				<Fragment key={index}>
					<li className={crumb.pathname === location.pathname ? "text-primary" : ""}>
						<Link to={crumb.pathname}>{crumb.handle.crumb}</Link>
					</li>
					{index !== length - 1 && (
						<li className="">
							<RightArrowHeadIcon />
						</li>
					)}
				</Fragment>
			))}
		</ol>
	);
};

export default BreadCrumbs;
