import { ErrorMessage } from "formik";
import React from "react";

const CustomInputWrapper = ({ label, containerClassName, children, ...props }) => {
	return (
		<div className={containerClassName}>
			{!!label && <label className="mb-3 block font-medium text-[1.6rem] text-bodyText2">{label}</label>}
			{children}
			{!!props["name"] && <ErrorMessage name={props["name"]} component="div" className="block mt-2 text-[1.2rem] text-red-500" />}
		</div>
	);
};

export default CustomInputWrapper;
