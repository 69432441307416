import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import { assignApplicantToJudge, fellowshipJudges } from "src/api";
import { CustomInput, CustomSelect } from "src/components";
import { useModalContext } from "src/components/Modal";
import { renderSuccessMessage } from "src/utilities/functions";
import * as Yup from "yup";

const loadOptions = async (inputValue, fellowshipId) => {
	if (!inputValue) return;

	const data = (await fellowshipJudges({ fellowship: fellowshipId, q: inputValue })).data.map((fellowship) => ({
		value: fellowship.id,
		label: `${fellowship.first_name} ${fellowship.last_name}`,
	}));
	return data;
};

const AssignToJudge = ({ applicants = [], fellowship }) => {
	const { id } = useParams();
	const { setIsOpen } = useModalContext();

	const initialValues = {
		fellowship: id,
		applicants: applicants?.map((applicant) => ({
			value: applicant.id,
			label: `${applicant.first_name} ${applicant.last_name}`,
			info: applicant,
		})),
		judges: null,
	};
	const validationSchema = Yup.object().shape({
		applicants: Yup.array().min(1, "At least 1 applicant must be provided"),
		fellowship: Yup.string().required("Please provide a program"),
		judges: Yup.array().min(1, "At least 1 judge must be provided"),
	});

	const { mutate, isLoading } = useMutation(assignApplicantToJudge, {
		onSuccess: ({ message }) => renderSuccessMessage(message).then(() => setIsOpen(false)),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				const { applicants, judges, ...rest } = values;
				const data = {
					...rest,
					applicants: applicants?.map((applicant) => applicant.value),
					judges: judges?.map((judge) => judge.value),
				};
				mutate(data);
			}}
		>
			{({ values, setFieldValue, handleSubmit }) => (
				<form className="grid gap-10" onSubmit={handleSubmit}>
					<CustomInput label="Program" value={fellowship?.title} disabled />
					<CustomSelect
						label="Applicant Name(s)"
						placeholder="Select applicant"
						name="applicants"
						searchable
						isMulti
						value={values.applicants}
						onChange={(values) => setFieldValue("applicants", values)}
						components={{ DropdownIndicator: null }}
					/>
					<CustomSelect
						label="Select judges"
						name="judges"
						placeholder="Select judges"
						className="w-full border-none"
						searchable
						isMulti
						value={values.judges}
						onChange={(values) => setFieldValue("judges", values)}
						loadOptions={(inputValue) => loadOptions(inputValue, id)}
					/>
					<div className="flex items-center justify-end">
						<button className="cancel-btn" type="button" onClick={() => setIsOpen(false)}>
							Cancel
						</button>
						<button className="py-4 primary-btn btn-loader" disabled={isLoading}>
							Assign
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default AssignToJudge;
