import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "src/api";
import { CustomInput } from "src/components";
import { renderSuccessMessage } from "src/utilities/functions";
import * as Yup from "yup";

const ChangePassword = () => {
	const { token } = useParams();
	const navigate = useNavigate();

	const initialValues = {
		token,
		password: "",
		password_confirmation: "",
	};
	const validationSchema = Yup.object().shape({
		password: Yup.string().required("This field is required"),
		password_confirmation: Yup.string()
			.oneOf([Yup.ref("password"), null], "Passwords must match")
			.required("This field is required"),
	});

	const { mutate, isLoading } = useMutation(resetPassword, {
		onSuccess: ({ message }) => renderSuccessMessage(message).then(() => navigate("/login")),
	});

	if (!token) return <Navigate to="/login" />;

	return (
		<div className="grid grid-rows-[max-content,1fr] min-h-screen p-14">
			<h1 className="font-Roobert font-bold text-[2.81rem] text-[#1F1D1D] mb-20">Kohort</h1>
			<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
				{({ values, handleChange, handleSubmit }) => (
					<form className="mx-auto my-auto min-w-[450px]" onSubmit={handleSubmit}>
						<h1 className="text-headerText text-[4rem] font-bold leading-[1.1] text-center mb-6">Change Password</h1>
						<p className="max-w-[500px] text-bodyText text-[1.4rem] text-center mb-20">
							Create a new password below to secure your account.
						</p>
						<CustomInput
							label="New password"
							value={values.password}
							onChange={handleChange}
							placeholder="New password"
							name="password"
							type="password"
							containerClassName="mb-6"
						/>
						<CustomInput
							label="Confirm password"
							value={values.password_confirmation}
							onChange={handleChange}
							placeholder="Confirm password"
							name="password_confirmation"
							type="password"
							containerClassName="mb-10"
						/>
						<button className="w-full py-5 primary-btn btn-loader mb-14" disabled={isLoading}>
							Reset Password
						</button>
					</form>
				)}
			</Formik>
		</div>
	);
};

export default ChangePassword;
