import { Suspense } from "react";
import "./App.css";
import "./global.css";
import "react-tabs/style/react-tabs.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Loader } from "./components";
import AppContextProvider from "./context/AppContext";
import { ProSidebarProvider } from "react-pro-sidebar";
import {
	AcceptInvitation,
	Contact,
	EngagementActivities,
	FellowshipDetails,
	Fellowships,
	Landing,
	Messaging,
	MyFellowships,
	NotFound,
	Notifications,
	Profile,
} from "./pages";
import { AuthenticationLayout, DashboardLayout, LandingLayout } from "./layouts";
import {
	Dashboard,
	MyFellowships as CreatorFellowships,
	FellowshipDetails as CreatorFellowshipDetails,
	FellowshipApplicants as CreatorFellowshipApplicants,
	Judges,
	FellowshipJudges,
} from "./pages/creator";
import {
	MyFellowships as JudgeFellowships,
	FellowshipDetails as JudgeFellowshipDetails,
	FellowshipApplicants as JudgeFellowshipApplicants,
} from "./pages/judge";
import { ChangePassword, CheckMail, FinalizeRegister, Login, Register, ResetPassword, Verify } from "./pages/authentication";

function App() {
	const router = createBrowserRouter([
		{
			path: "/",
			element: <LandingLayout />,
			children: [
				{ index: true, element: <Landing /> },
				{ path: "profile", element: <Profile /> },
				{ path: "contact", element: <Contact /> },
				{ path: "messages", element: <Messaging /> },
				{
					path: "programs",
					children: [
						{ index: true, element: <Fellowships /> },
						{ path: ":id", element: <FellowshipDetails /> },
						{ path: ":id/milestones", element: <EngagementActivities /> },
					],
				},
				{ path: "my-programs", element: <MyFellowships /> },
				{ path: "notifications", element: <Notifications /> },
			],
		},
		{
			path: "/creator",
			element: <DashboardLayout />,
			children: [
				{ index: true, element: <Dashboard /> },
				{
					path: "my-programs",
					handle: { crumb: "My Programs" },
					children: [
						{
							index: true,
							element: <CreatorFellowships />,
						},
						{
							path: ":id",
							handle: { crumb: "View Program" },
							children: [
								{ index: true, element: <CreatorFellowshipDetails /> },
								{
									path: "applicants",
									handle: { crumb: "Applicants" },
									element: <CreatorFellowshipApplicants />,
								},
								{
									path: "judges",
									handle: { crumb: "Judges" },
									element: <FellowshipJudges />,
								},
							],
						},
					],
				},
				{ path: "judges", element: <Judges /> },
				{ path: "messages", element: <Messaging creator /> },
				{ path: "settings", element: <div>SETTINGS</div> },
			],
		},
		{
			path: "/judge",
			element: <DashboardLayout />,
			handle: { crumb: "My Programs" },
			children: [
				{
					index: true,
					element: <JudgeFellowships />,
				},
				{
					path: ":id",
					handle: { crumb: "View Program" },
					children: [
						{ index: true, element: <JudgeFellowshipDetails /> },
						{
							path: "applicants",
							handle: { crumb: "Applicants" },
							element: <JudgeFellowshipApplicants />,
						},
					],
				},
				{ path: "settings", element: <div>SETTINGS</div> },
			],
		},
		{
			path: "/",
			element: <AuthenticationLayout />,
			children: [
				{ path: "login", element: <Login /> },
				{
					path: "reset-password",
					children: [
						{ index: true, element: <ResetPassword /> },
						{ path: ":token", element: <ChangePassword /> },
					],
				},

				{ path: "check-email", element: <CheckMail /> },
				{ path: "verify-email", element: <Verify /> },
				{ path: "register", element: <Register /> },
				{ path: "finalise-register", element: <FinalizeRegister /> },
			],
		},
		{ path: "accept", element: <AcceptInvitation /> },
		{
			path: "*",
			element: <NotFound />,
		},
	]);

	return (
		<Suspense fallback={<Loader />}>
			<ProSidebarProvider>
				<AppContextProvider>
					<RouterProvider router={router} />
				</AppContextProvider>
			</ProSidebarProvider>
		</Suspense>
	);
}

export default App;
