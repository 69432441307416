import React, { forwardRef } from "react";
import { useAppContext } from "src/context/AppContext";
import { cn } from "src/utilities/utils";

const Message = forwardRef(({ message }, ref) => {
	const { user } = useAppContext();

	const shouldBeLeft = message?.sender_id === user?.id;

	return (
		<div
			ref={ref}
			className={cn(
				"max-w-[270px] md:max-w-[300px] lg:max-w-[550px] mb-2 text-[1.5rem] font-medium px-6 py-5 h-fit w-fit leading-[1.5]",
				shouldBeLeft
					? "bg-primary ml-auto rounded-tr-[1.6rem] rounded-tl-[1.6rem] rounded-bl-[1.6rem] rounded-br-[0.4rem]"
					: "rounded-tr-[1.6rem] rounded-tl-[1.6rem] rounded-br-[1.6rem] rounded-bl-[0.4rem] bg-[#EFEFEF]"
			)}
		>
			<p className={cn(shouldBeLeft ? "text-white" : "text-headerText")}>{message?.message}</p>
		</div>
	);
});

export default Message;
