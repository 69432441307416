import { useQueries } from "@tanstack/react-query";
import React from "react";
import { creatorFellowshipDetails, getFellowshipSubmittedProposals } from "src/api";
import { AttachmentIcon } from "src/assets/svg";
import { extractFileName, renderCurrency } from "src/utilities/functions";

const ApplicantProposals = ({ fellowship, applicant }) => {
	const [{ data: rawFellowship }, { data: rawProposal }] = useQueries({
		queries: [
			{
				queryKey: ["creator-fellowship", fellowship],
				queryFn: () => creatorFellowshipDetails(fellowship),
				suspense: true,
			},
			{
				queryKey: ["fellowship-proposal", fellowship, applicant],
				queryFn: () => getFellowshipSubmittedProposals(fellowship, applicant),
				suspense: true,
			},
		],
	});

	const fellowshipInfo = rawFellowship?.data?.fellowship;
	const proposal = rawProposal?.data;

	return (
		<div>
			<div className="flex flex-wrap items-center justify-between mb-12">
				<h3 className="text-[2rem] text-headerText font-semibold font-Roobert">Proposal Terms</h3>
				<p className="text-primary text-[1.6rem] font-semibold">Project Budget: {renderCurrency(fellowshipInfo?.project_budget)}</p>
			</div>
			<div>
				<p className="text-[1.8rem] text-[#101928] font-bold">How do they want to be paid?</p>
				<p className="text-[1.6rem] text-bodyText2 font-medium mb-12 capitalize">{proposal?.payment_frequency} Payment</p>
				{proposal?.payment_frequency === "milestone" ? (
					<>
						<div className="grid gap-8 mb-16">
							{proposal?.milestones?.map((milestone, index) => (
								<Milestone milestone={milestone} index={index} key={index} />
							))}
						</div>
						<div className="flex items-center justify-between py-[2.5rem] border-y border-[#919BAF66]">
							<div>
								<p className="text-[#101928] text-[1.8rem] font-bold">Total:</p>
								<p className="text-[#818B9C] text-[1.4rem]">
									This is the price the program creator will see. It includes all the milestones together.
								</p>
							</div>
							<p className="text-primary font-semibold text-[1.6rem]">{renderCurrency(proposal?.total_amount)}</p>
						</div>
					</>
				) : (
					<p className="text-[#101928] text-[1.6rem] font-semibold">Bid: {renderCurrency(proposal?.bid)}</p>
				)}
				<p className="font-bold text-[1.8rem] text-headerText mt-14 mb-9">Attachments and other Information</p>
				<p className="text-bodyText2 text-[1.6rem]">{proposal.additional_info}</p>
				<div className="mt-10">
					{proposal?.proposals?.map((attachment, index) => (
						<Attachment attachment={attachment} key={index} />
					))}
				</div>
			</div>
		</div>
	);
};

const Milestone = ({ milestone, index }) => {
	return (
		<div className="grid grid-cols-[3rem,1fr,1fr,1fr] gap-4 overflow-scroll">
			<p className="text-[1.4rem] text-black font-semibold pt-1">{index + 1}.</p>
			<div>
				<p className="text-black text-[1.6rem] font-semibold">Description</p>
				<p className="text-bodyText text-[1.4rem]">{milestone?.description}</p>
			</div>
			<div>
				<p className="text-black text-[1.6rem] font-semibold">Due</p>
				<p className="text-bodyText text-[1.4rem]">{milestone?.due_date}</p>
			</div>
			<div>
				<p className="text-black text-[1.6rem] font-semibold">Amount</p>
				<p className="text-bodyText text-[1.4rem]">{renderCurrency(milestone?.amount)}</p>
			</div>
		</div>
	);
};

const Attachment = ({ attachment }) => {
	return (
		<a href={attachment} download={true} className="flex items-center gap-4 mb-2 text-[1.25rem] text-primary">
			<AttachmentIcon /> <span>{extractFileName(attachment)}</span>
		</a>
	);
};

export default ApplicantProposals;
