import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Formik } from "formik";
import React, { useState } from "react";
import { getEngagementDetails, setEngagementStatus } from "src/api";
import { ContractIcon } from "src/assets/svg";
import { CustomTextArea, Modal } from "src/components";
import { useModalContext } from "src/components/Modal";
import { renderConfirmDialogue, renderCurrency, renderSuccessMessage } from "src/utilities/functions";
import * as Yup from "yup";

const Contract = ({ fellowship }) => {
	const queryClient = useQueryClient();

	const [setModalOpen, setSetModalOpen] = useState(false);

	const { data } = useQuery({
		queryKey: ["engagement", fellowship?.uuid],
		queryFn: () => getEngagementDetails(fellowship?.uuid),
		suspense: true,
	});

	const engagement = data?.data;

	const { mutate, isLoading } = useMutation(setEngagementStatus, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(
				async () => await queryClient.refetchQueries({ queryKey: ["fellowship", fellowship?.uuid], type: "all" })
			),
	});

	const setStatus = (status) =>
		renderConfirmDialogue().then((result) => {
			if (result) mutate({ engagement: engagement?.uuid, fellowship: fellowship?.uuid, status });
		});

	return (
		<>
			<div className="max-w-[1000px] mx-auto grid pt-[5rem] pb-[10rem]">
				<ContractIcon className="mx-auto" />
				<h1 className="text-center text-[#001439] font-Roobert text-[2rem] font-semibold mt-7 mb-2">
					Congratulations! You have received an offer!
				</h1>
				<p className="text-[#001439] text-center text-[1.6rem] mb-[8rem]">
					Review the contract terms for your milestone-based offer from <span className="font-semibold">{fellowship?.title}</span>
				</p>
				<h2 className="text-[1.8rem] text-[#001439] mb-4 font-semibold">Scope of work</h2>
				<p className="text-[1.6rem] text-bodyText pb-[5rem] border-b border-[#919BAF66]">{engagement?.scope_of_work}</p>
				<div className="grid gap-8 grid-cols-[repeat(auto-fit,minmax(155px,1fr))] py-12">
					<InfoCard text={engagement?.project_duration} information="Project Duration" textClassName="!normal-case" />
					<InfoCard text={renderCurrency(engagement?.budget)} information="Budget" />
					<InfoCard text={engagement?.payment_type?.replace("-", " ")} information="Payment Type" />
				</div>
				{engagement?.payment_type === "milestone" ? (
					<>
						<h2 className="text-[1.8rem] text-[#001439] mb-6 font-semibold">Milestone Timeline</h2>
						<div className="grid gap-8 mb-16 pb-[5rem] border-b border-[#919BAF66]">
							{engagement?.milestones?.map((milestone, index) => (
								<Milestone milestone={milestone} index={index} key={index} />
							))}
							<p className="text-[#001439] text-[1.6rem] font-bold">Total: {renderCurrency(engagement?.total_amount)}</p>
						</div>
					</>
				) : (
					<div className="grid gap-8 mb-16 pb-[5rem] border-b border-[#919BAF66]">
						<p className="text-[#001439] text-[1.6rem] font-bold">Fixed Price: {renderCurrency(engagement?.fixed_price)}</p>
					</div>
				)}
				{!!engagement?.additional_terms && (
					<>
						<h2 className="text-[1.8rem] text-[#001439] mb-6 font-semibold">Terms</h2>
						<p className="text-[#001439] text-[1.4rem] font-medium">Additional Terms</p>
						<p className="text-bodyText text-[1.6rem]">{engagement?.additional_terms}</p>
					</>
				)}
				<div className="flex items-center justify-end mt-14">
					<button className="cancel-btn btn-loader" type="button" onClick={() => setSetModalOpen(true)} disabled={isLoading}>
						Reject
					</button>
					<button className="px-8 py-4 primary-btn btn-loader" type="button" onClick={() => setStatus("accepted")} disabled={isLoading}>
						Accept
					</button>
				</div>
			</div>
			<Modal isOpen={setModalOpen} size="" setIsOpen={setSetModalOpen} title={`Rejection Reason`}>
				<RejectionReason fellowship={fellowship} engagement={engagement} />
			</Modal>
		</>
	);
};
//
const Milestone = ({ milestone, index }) => {
	return (
		<div className="grid grid-cols-[3rem,1fr,1fr,1fr] gap-4 overflow-scroll">
			<p className="text-[1.4rem] text-black font-semibold pt-1">{index + 1}.</p>
			<div>
				<p className="text-black text-[1.6rem] font-semibold">Description</p>
				<p className="text-bodyText text-[1.4rem]">{milestone?.description}</p>
			</div>
			<div>
				<p className="text-black text-[1.6rem] font-semibold">Due</p>
				<p className="text-bodyText text-[1.4rem]">{milestone?.due_date}</p>
			</div>
			<div>
				<p className="text-black text-[1.6rem] font-semibold">Amount</p>
				<p className="text-bodyText text-[1.4rem]">{renderCurrency(milestone?.amount)}</p>
			</div>
		</div>
	);
};

const InfoCard = ({ text, icon, information, textClassName }) => {
	return (
		<div className="text-headerText">
			<p className={`flex items-center capitalize gap-3 text-[1.8rem] font-semibold ${textClassName}`}>
				{icon} {text}
			</p>
			<p className="text-[1.4rem] capitalize text-[#778499]">{information}</p>
		</div>
	);
};

const RejectionReason = ({ fellowship, engagement }) => {
	const queryClient = useQueryClient();
	const { setIsOpen } = useModalContext();

	const initialValues = {
		reason: "",
	};
	const validationSchema = Yup.object().shape({
		reason: Yup.string().required("This field is required"),
	});

	const { mutate, isLoading } = useMutation(setEngagementStatus, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(
				async () => await queryClient.refetchQueries({ queryKey: ["fellowship", fellowship?.uuid], type: "all" })
			),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => mutate({ engagement: engagement?.uuid, fellowship: fellowship?.uuid, status: "rejected", ...values })}
		>
			{({ values, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="grid">
					<CustomTextArea
						value={values.reason}
						placeholder="Reject reason"
						label="Reject reason"
						onChange={handleChange}
						rows={5}
						name="reason"
					/>
					<div className="flex items-center justify-end mt-14">
						<button className="cancel-btn" type="button" onClick={() => setIsOpen(false)}>
							Cancel
						</button>
						<button type="submit" className="px-6 py-4 primary-btn btn-loader" disabled={isLoading}>
							Submit Engagement
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default Contract;
