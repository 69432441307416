import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { login } from "src/api";
import { EmailIcon } from "src/assets/svg";
import { CustomInput } from "src/components";
import { useAppContext } from "src/context/AppContext";
import { setToStorage } from "src/utilities/functions";
import * as Yup from "yup";

const Login = () => {
	const { setUser, setToken } = useAppContext();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const initialValues = {
		email: "",
		password: "",
	};
	const validationSchema = Yup.object().shape({
		email: Yup.string().email("Please provide a valid email").required("This field is required"),
		password: Yup.string().required("This field is required"),
	});

	const { mutate, isLoading } = useMutation(login, {
		onSuccess: ({ data }) => {
			setToStorage("user", data.user);
			setToStorage("token", data.access_token);
			setUser(data.user);
			setToken(data.access_token);
			if (searchParams.has("redirect")) {
				navigate(searchParams.get("redirect"));
			}
		},
	});

	return (
		<div className="grid grid-rows-[max-content,1fr] min-h-screen p-14">
			<h1 className="font-Roobert font-bold text-[2.81rem] text-[#1F1D1D] mb-20">Kohort</h1>
			<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
				{({ values, handleChange, handleSubmit }) => (
					<form className="mx-auto my-auto" onSubmit={handleSubmit}>
						<h1 className="text-headerText text-[4rem] font-bold leading-[1.1] text-center mb-6">Welcome to Kohort</h1>
						<p className="max-w-[500px] text-bodyText text-[1.4rem] text-center mb-20">
							Kohort is an online platform where individuals and organisations can come together to collaborate, apply for
							opportunities, etc.{" "}
						</p>
						<CustomInput
							label="Work Email"
							value={values.email}
							onChange={handleChange}
							placeholder="Email"
							name="email"
							innerContainerClassName="!px-6"
							icon={<EmailIcon className="text-bodyText" />}
							containerClassName="mb-6"
						/>
						<CustomInput
							label="Password"
							type="password"
							value={values.password}
							onChange={handleChange}
							placeholder="Password"
							name="password"
							innerContainerClassName="!px-6"
							containerClassName="mb-14"
						/>
						<button className="w-full py-5 primary-btn btn-loader mb-14" disabled={isLoading}>
							Login
						</button>
						<p className="text-[1.6rem] text-black text-center mb-1">
							Don't have an account?
							<Link to="/register" className="font-medium text-primary hover:underline">
								{" "}
								Sign Up
							</Link>
						</p>
						<p className="text-[1.6rem] text-black text-center">
							Forgot password?
							<Link to="/reset-password" className="font-medium text-primary hover:underline">
								{" "}
								Reset Password
							</Link>
						</p>
					</form>
				)}
			</Formik>
		</div>
	);
};

export default Login;
