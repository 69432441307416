import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import TopNav from "./TopNav";
import Footer from "./Footer";

const LandingLayout = () => {
	return (
		<Container>
			<TopNav />
			<Outlet />
			<Footer />
		</Container>
	);
};

const Container = styled.div`
	& > * {
		padding-left: 6rem;
		padding-right: 6rem;
	}

	& > div.thin {
		padding-left: 1rem !important;
		padding-right: 1rem !important;
	}

	@media only screen and (max-width: 800px) {
		& > * {
			padding-left: 3rem;
			padding-right: 3rem;
		}
	}
`;

export default LandingLayout;
