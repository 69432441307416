import React from "react";
import { useNavigate } from "react-router-dom";
import { LeftArrowIcon, NotFoundIcon } from "src/assets/svg";

const NotFound = () => {
	const navigate = useNavigate();
	return (
		<div className="flex flex-col items-center justify-center min-h-screen px-[3rem]">
			<NotFoundIcon className="max-sm:max-w-[300px]" />
			<h1 className="font-Roobert font-semibold text-[4rem] leading-[1.2] text-black mb-4 text-center">Oops! Page Not Found</h1>
			<p className="text-[1.6rem] leading-[1.45] max-w-[600px] text-bodyText mb-10">
				Something went wrong. It looks like the page you requested could not be found. This is not a fault, just an accident that was not
				intentional.
			</p>
			<div className="flex gap-6">
				<button className="primary-btn border-[#0F216D] bg-[#0F216D] hover:text-[#0F216D]" onClick={() => navigate(-1)}>
					<LeftArrowIcon /> Go Back
				</button>
				<button className="text-[#0F216D] border border-[919BAF66] rounded-full px-[3.5rem] py-5" onClick={() => navigate("/")}>
					Home
				</button>
			</div>
		</div>
	);
};

export default NotFound;
