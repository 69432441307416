import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { ErrorMessage, Formik, useFormikContext } from "formik";
import React from "react";
import { CustomEditor, CustomPopup, Loader } from "src/components";
import { convertToEditorState, htmlHasText, removeItemAtIndex, renderSuccessMessage } from "src/utilities/functions";
import * as Yup from "yup";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { AddIcon, CollapseIcon, CopyIcon, DeleteIcon, OpenIcon, OptionsIcon } from "src/assets/svg";
import { useModalContext } from "src/components/Modal";
import Question from "./Question";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createApplicationForm, editApplicationForm, getApplicationForm } from "src/api";
import { useNavigate } from "react-router-dom";

const questionExample = { title: "Question Title", is_required: false, form_field_type_id: 1 };
const sectionExample = { title: "Section Title", questions: [{ ...questionExample }] };

export function appendToSectionQuestions(sections, index = null, value = null) {
	const newSections = sections.map((section, idx) => {
		if (index === null) {
			return {
				...section,
				questions: [...section.questions, { ...(!!value ? value : questionExample) }],
			};
		}
		if (idx === index) {
			return {
				...section,
				questions: [...section.questions, { ...(!!value ? value : questionExample) }],
			};
		}
		return section;
	});

	return newSections;
}

const FellowshipQuestions = ({ fellowshipId }) => {
	const { setIsOpen } = useModalContext();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const { data, isLoading: isFellowshipLoading } = useQuery({
		queryKey: ["application-form", fellowshipId],
		queryFn: () => getApplicationForm(fellowshipId),
	});

	const applicationForm = data?.data;

	const initialValues = {
		fellowshipId: fellowshipId,
		id: !!applicationForm ? applicationForm?.id : null,
		title: applicationForm?.title ?? "Application Form Title",
		description: applicationForm?.description ? convertToEditorState(applicationForm?.description) : convertToEditorState("Form description"),
		sections: applicationForm?.sections ?? [sectionExample],
	};
	const validationSchema = Yup.object().shape({
		title: Yup.string().required("This field is required"),
		description: Yup.mixed().test("is-empty", "This field is required", (value) =>
			htmlHasText(draftToHtml(convertToRaw(value.getCurrentContent())))
		),
		sections: Yup.array()
			.of(
				Yup.object().shape({
					title: Yup.string().required("This field is required"),
					questions: Yup.array()
						.of(
							Yup.object().shape({
								title: Yup.string().required("This field is required"),
								is_required: Yup.boolean(),
								options: Yup.array().of(Yup.string()).nullable(),
							})
						)
						.min(1, "Every section must have at least 1 question"),
				})
			)
			.min(1, "Form must have at least 1 section"),
	});

	const { mutate, isLoading } = useMutation(!!applicationForm ? editApplicationForm : createApplicationForm, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(async () => {
				if (!!applicationForm) {
					await queryClient.removeQueries({ queryKey: ["application-form", fellowshipId] });
				} else {
					navigate("/creator/my-programs");
				}
				setIsOpen(false);
			}),
	});

	if (isFellowshipLoading) return <Loader />;

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				const { description } = values;
				const data = {
					...values,
					description: draftToHtml(convertToRaw(description.getCurrentContent())),
				};
				mutate(data);
			}}
		>
			{({ values, handleChange, handleSubmit, setFieldValue }) => (
				<form onSubmit={handleSubmit} className="grid gap-10">
					<Input
						value={values.title}
						onChange={handleChange}
						name="title"
						className="font-Roobert w-full text-[2rem] leading-[1.2] font-semibold text-black"
					/>
					<CustomEditor
						setState={setFieldValue}
						name="description"
						state={values.description}
						toolbarClassName=""
						editorClassName="border-b border-[#919BAF66] resize-y !text-bodyText2 text-[1.4rem] pt-8"
					/>
					<Accordion allowMultiple>
						{values.sections.map((section, sectionIndex) => {
							const itemKey = `accordion-${sectionIndex}`;
							return (
								<AccordionItem
									itemKey={itemKey}
									key={itemKey}
									header={(props) => <AccordionHeader {...props} itemKey={itemKey} index={sectionIndex} key={itemKey} />}
								>
									{section.questions.map((question, questionIndex) => (
										<Question
											key={`question-${sectionIndex}-${questionIndex}`}
											questionIndex={questionIndex}
											sectionIndex={sectionIndex}
											question={question}
										/>
									))}
								</AccordionItem>
							);
						})}
					</Accordion>
					<button
						type="button"
						className="flex gap-3 text-headerText bg-[#F4F4F4] font-semibold text-[1.6rem] items-center justify-center py-4 rounded-full mt-14"
						onClick={() => setFieldValue("sections", [...values.sections, { ...sectionExample }])}
					>
						<AddIcon /> Add Section
					</button>
					<ErrorMessage component="div" className="block mt-2 text-[1.2rem] text-red-500" name="sections" />
					<div className="flex items-center justify-end">
						<button className="cancel-btn" type="button" onClick={() => setIsOpen(false)}>
							Cancel
						</button>
						<button className="py-4 primary-btn btn-loader" disabled={isLoading}>
							Submit
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

const AccordionHeader = ({ index, state }) => {
	const { values, handleChange, setFieldValue } = useFormikContext();

	const isOpen = state.isEnter;

	return (
		<div className="flex items-center justify-between w-full gap-4">
			<div className="px-4">
				<Input
					value={values.sections[index].title}
					onChange={handleChange}
					name={`sections[${index}].title`}
					className="font-Roobert text-[1.8rem] w-full flex-1 py-3 font-medium text-bodyText2 p-0 !border-0 focus-within:!border-b focus:border-primary"
				/>
				<ErrorMessage name={`sections[${index}].questions`} className="block mt-2 text-[1.2rem] text-red-500" component="div" />
			</div>
			<div className="flex items-center text-bodyText">
				<button type="button" className="p-2 rounded-full hover:bg-gray-200 ">
					{isOpen ? <CollapseIcon /> : <OpenIcon className="w-[18px] h-[18px]" />}
				</button>
				<CustomPopup
					trigger={
						<button type="button" className="p-2 rounded-full hover:bg-gray-200 ">
							<OptionsIcon />
						</button>
					}
					keepTooltipInside=".modal-body"
					options={[
						{
							text: (
								<>
									<CopyIcon /> <p className="text-[1.4rem] font-medium">Duplicate</p>
								</>
							),
							onClick: () => setFieldValue("sections", [...values.sections, { ...values.sections[index] }]),
						},
						{
							text: (
								<>
									<AddIcon /> <p className="text-[1.4rem] font-medium">Add question</p>
								</>
							),
							onClick: () => setFieldValue("sections", appendToSectionQuestions(values.sections, index)),
						},
						{
							text: (
								<>
									<DeleteIcon className="!text-red-500" /> <p className="!text-red-500 text-[1.4rem] font-medium">Delete section</p>
								</>
							),
							onClick: () => setFieldValue("sections", removeItemAtIndex(values.sections, index)),
						},
					]}
				/>
			</div>
		</div>
	);
};

export const Input = ({ className, ...props }) => {
	return (
		<div className="flex flex-col max-[425px]:min-w-[200px] flex-1 items-start">
			<input type="text" {...props} className={`border-0 border-b border-headerText ${className}`} autoComplete="off" />
			<ErrorMessage component="div" className="block mt-2 text-[1.2rem] text-red-500" name={props["name"]} />
		</div>
	);
};

export default FellowshipQuestions;
