import { format } from "date-fns";
import React from "react";
import avatar from "src/assets/avatar.png";
import { DocumentFilledIcon, NoFellowshipIcon, UserFilledIcon, UserGroupFilledIcon } from "src/assets/svg";
import { CustomDataTable, EmptyState } from "src/components";
import { Link, useNavigate } from "react-router-dom";
import { creatorDashboard } from "src/api";
import { useQuery } from "@tanstack/react-query";
import { renderNumber } from "src/utilities/functions";
import { useAppContext } from "src/context/AppContext";

const columns = [
	{
		name: "Name & Email",
		cell: (row) => (
			<div className="overflow-hidden">
				<p className="text-headerText">
					{row?.first_name} {row?.last_name}
				</p>
				<p className="mt-1 lowercase truncate text-bodyText">{row?.email}</p>
			</div>
		),
	},
	{
		name: "Phone Number",
		selector: (row) => row?.phone_number,
	},
	{
		name: "Date Applied",
		selector: (row) => format(row?.date_applied || "2024-06-24", "dd MMMM yyyy"),
	},
	{
		name: "Judges Assigned",
		selector: (row) => renderNumber(row.judges_assigned),
	},
	{
		name: "Program",
		cell: (row) => <Link to={`my-programs/${row?.uuid}`}>{row?.fellowship_name}</Link>,
		style: { color: "var(--header-text)" },
	},
];

const Dashboard = () => {
	const { user } = useAppContext();
	const navigate = useNavigate();

	const { data } = useQuery({
		queryKey: ["creator-dashboard"],
		queryFn: creatorDashboard,
		suspense: true,
	});

	const recentApplications = data?.data?.applications?.data;
	const totalApplications = data?.data?.totalApplications;
	const totalFellowships = data?.data?.totalFellowships;
	const totalJudges = data?.data?.totalJudges;

	return (
		<div>
			<div className="grid grid-cols-[max-content,1fr] items-center gap-3 py-3 mb-16">
				<div className="w-[60px] aspect-square rounded-full">
					<img src={avatar} className="w-full aspect-square" alt="" />
				</div>
				<div className="text-left">
					<p className="text-headerText overflow-hidden leading-[1.35] font-medium truncate text-[2.4rem]">Hello {user?.first_name}!</p>
					<p className="text-bodyText text-[1.4rem] truncate">{format(new Date(), "EEEE, do MMMM")}</p>
				</div>
			</div>
			<div className="grid gap-10 grid-cols-[repeat(auto-fit,minmax(300px,1fr))] mb-20">
				<div className="p-10 border border-[#919BAF66] rounded-xl flex gap-8">
					<div className="p-5 text-headerText border border-[#0F216D99] rounded-3xl">
						<DocumentFilledIcon />
					</div>
					<div>
						<p className="text-headerText text-[2.8rem] font-semibold leading-none">{renderNumber(totalApplications)}</p>
						<p className="font-medium text-bodyText">Applications</p>
					</div>
				</div>
				<div className="p-10 border border-[#919BAF66] rounded-xl flex gap-8">
					<div className="p-5 text-headerText border border-[#0F216D99] rounded-3xl">
						<UserFilledIcon />
					</div>
					<div>
						<p className="text-headerText text-[2.8rem] font-semibold leading-none">{renderNumber(totalJudges)}</p>
						<p className="font-medium text-bodyText">Judges Onboarded</p>
					</div>
				</div>
				<div className="p-10 border border-[#919BAF66] rounded-xl flex gap-8">
					<div className="p-5 text-headerText border border-[#0F216D99] rounded-3xl">
						<UserGroupFilledIcon />
					</div>
					<div>
						<p className="text-headerText text-[2.8rem] font-semibold leading-none">{renderNumber(totalFellowships)}</p>
						<p className="font-medium text-bodyText">Programs</p>
					</div>
				</div>
			</div>
			<CustomDataTable
				columns={columns}
				data={recentApplications}
				pagination={false}
				subHeader
				subHeaderAlign="left"
				subHeaderComponent={<h2 className="text-headerText text-[1.6rem] font-semibold">Recent Applications</h2>}
				noDataComponent={
					<EmptyState icon={<NoFellowshipIcon />}>
						<p className="font-Roobert font-medium text-[1.6rem] md:text-[2rem] text-center text-bodyText2">
							You have not joined any programs yet.
							<br />
							Please click the button below to view programs you can join
						</p>
						<button className="secondary-btn w-fit text-[1.4rem] py-5" onClick={() => navigate("my-programs")}>
							View Programs
						</button>
					</EmptyState>
				}
			/>
		</div>
	);
};

export default Dashboard;
