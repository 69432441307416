import React from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import {
	DashboardIcon,
	FellowshipIcon,
	JudgesIcon,
	MessagesIcon,
	// SettingsIcon
} from "src/assets/svg";

const SideBar = () => {
	const { pathname } = useLocation();

	const routes = pathname.startsWith("/creator")
		? [
				{ path: "/creator", icon: <DashboardIcon />, name: "Dashboard", isActive: pathname === "/creator" },
				{ path: "/creator/my-programs", icon: <FellowshipIcon />, name: "My programs" },
				{ path: "/creator/judges", icon: <JudgesIcon />, name: "Judges" },
				{ path: "/creator/messages", icon: <MessagesIcon />, name: "Messages" },
				// { path: "/creator/settings", icon: <SettingsIcon />, name: "Settings" },
		  ]
		: [
				{ path: "/judge", icon: <FellowshipIcon />, name: "My programs", isActive: pathname === "/judge" },
				// { path: "/judge/messages", icon: <MessagesIcon />, name: "Messages" },
				// { path: "/judge/settings", icon: <SettingsIcon />, name: "Settings" },
		  ];

	return (
		<Sidebar
			backgroundColor="white"
			width="240px"
			collapsedWidth="75px"
			customBreakPoint="1000px"
			rootStyles={{ backgroundColor: "white", borderColor: "#919BAF66" }}
		>
			<Menu rootStyles={{ paddingBlock: 40 }} closeOnClick>
				<h1 className="font-Roobert font-bold text-[2.81rem] text-[#1F1D1D] mx-auto mb-20">Kohort</h1>
				{routes.map(({ path, name, icon, isActive }, index) => (
					<MenuItem
						key={index}
						component={<Link to={path} />}
						active={isActive === undefined ? pathname.startsWith(path) : isActive}
						icon={icon}
					>
						{name}
					</MenuItem>
				))}
			</Menu>
		</Sidebar>
	);
};

export default SideBar;
