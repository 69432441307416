import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { fellowshipApplicantResponse } from "src/api";
import avatar from "src/assets/avatar.png";
import { UserReviews } from "src/components";
import { formatStringDate } from "src/utilities/functions";

const ApplicantDetails = ({ application }) => {
	const { id: fellowshipId } = useParams();

	const { data } = useQuery({
		queryKey: ["application-response", fellowshipId, application?.uuid],
		queryFn: () => fellowshipApplicantResponse(fellowshipId, application?.uuid),
		suspense: true,
	});

	const response = data?.data?.response;
	const sections = response?.sections;
	const applicant = data?.data?.applicant;

	return (
		<div>
			<h1 className="mb-10 dashboard-header">Applicant Details</h1>
			<Tabs className="grid" selectedTabClassName="text-primary bg-[#FDF3F0] !rounded-lg">
				<TabList className="flex gap-2 mx-auto overflow-scroll hide-scrollbar mb-14">
					<Tab className="py-4 min-w-fit capitalize px-5 hover:text-primary hover:bg-[#FDF3F0] cursor-pointer font-medium text-[1.5rem] text-bodyText">
						Reviews
					</Tab>
					{sections.map((section) => (
						<Tab
							key={section.id}
							className="py-4 min-w-fit capitalize px-5 hover:text-primary hover:bg-[#FDF3F0] cursor-pointer font-medium text-[1.5rem] text-bodyText"
						>
							{section.title}
						</Tab>
					))}
				</TabList>
				<TabPanel>
					<UserReviews user={applicant?.uuid}>
						{(other) => <ApplicantInfo applicant={applicant} response={response} child={other} />}
					</UserReviews>
				</TabPanel>
				{sections.map((section, index) => (
					<TabPanel key={section.id}>
						<Panel questions={section.questions} applicant={applicant} response={response} />
					</TabPanel>
				))}
			</Tabs>
		</div>
	);
};

const Panel = ({ questions, applicant, response }) => {
	return (
		<div>
			<ApplicantInfo applicant={applicant} response={response} />
			<div className="grid gap-14">
				{questions?.map((question, key) => (
					<Info key={key} question={question} disabled />
				))}
			</div>
		</div>
	);
};

const Info = ({ question, innerContainerClassName, className }) => {
	return (
		<div className="grid min-[550px]:gap-6 min-[550px]:grid-cols-[250px,1fr]">
			<label className="mb-3 block capitalize font-medium text-[1.6rem] text-bodyText2">{question?.title}</label>
			{Array.isArray(question.answer) ? (
				<div className="flex gap-6 max-w-[550px] flex-wrap">
					{question?.answer?.map((response, index) => (
						<p key={index} className="text-[1.4rem] rounded-full font-medium text-bodyText px-6 py-4 bg-[#EFEFEF]">
							{response}
						</p>
					))}
				</div>
			) : question?.form_field_type === "paragraph" ? (
				<textarea
					rows="5"
					className={`border rounded-md w-full border-lightGray focus:![--tw-ring-shadow:transparent] placeholder:text-bodyText focus:border-primary px-8 py-4 text-[1.6rem] placeholder:text-[1.6rem] text-bodyText`}
					value={question?.answer}
					disabled
				></textarea>
			) : question?.form_field_type === "file_upload" ? (
				<a
					href={question?.answer}
					target="_blank"
					download={true}
					className="px-4 py-3 font-medium text-white rounded-md bg-primary w-fit"
					rel="noreferrer"
				>
					Download file
				</a>
			) : (
				<div
					className={`flex items-center gap-4 border rounded-md border-lightGray px-8 py-4 text-[1.6rem] has-[:disabled]:bg-[#FBFBFB] has-[:focus]:border-primary min-w-[250px] ${innerContainerClassName}`}
				>
					<input
						value={question?.answer}
						disabled
						className={`!border-0 focus:![--tw-ring-shadow:transparent] !outline-0 placeholder:text-bodyText text-bodyText text-[1.6rem] w-full p-0 ${className}`}
					/>
				</div>
			)}
		</div>
	);
};

const ApplicantInfo = ({ applicant, response, child }) => {
	return (
		<div className="border border-[#919BAF4D] py-6 rounded-lg px-8 grid gap-6 divide-y mb-14">
			<div className="grid grid-cols-[max-content,1fr] items-center gap-3 ">
				<div className="w-[40px] aspect-square rounded-full">
					<img src={avatar} alt="" />
				</div>
				<div className="text-left">
					<p className="text-headerText overflow-hidden font-medium truncate text-[1.5rem]">
						{applicant?.first_name} {applicant?.last_name}
					</p>
					<p className="text-bodyText text-[1.4rem] truncate">{applicant?.email}</p>
				</div>
			</div>
			<div className="flex flex-wrap pt-5 overflow-scroll gap-x-10 gap-y-4">
				<p className="flex gap-2 text-bodyText font-medium text-[1.6rem]">
					Date Applied: <span className="text-headerText">{formatStringDate(applicant?.date_applied || "2024-06-24")}</span>
				</p>
				<p className="flex gap-2 text-bodyText font-medium text-[1.6rem]">
					Program: <span className="text-headerText">{response?.fellowship_name}</span>
				</p>
				<p className="flex gap-2 text-bodyText font-medium text-[1.6rem]">
					Judges Assigned : <span className="text-headerText">{response?.judges_assigned}</span>
				</p>
			</div>
			{child}
		</div>
	);
};

export default ApplicantDetails;
