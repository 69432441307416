import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getFellowship } from "src/api";
import { NotAppliedDisplay } from ".";

const LoggedOutDetails = () => {
	const { id } = useParams();

	const { data } = useQuery({
		queryKey: ["fellowship", id],
		queryFn: () => getFellowship(id),
		suspense: true,
	});

	const fellowship = data?.data;

	return <NotAppliedDisplay fellowship={fellowship} />;
};

export default LoggedOutDetails;
