import React, { Suspense, useEffect, useState } from "react";
import { CustomInput, Loader } from "..";
import { CloseIcon, SearchIcon } from "src/assets/svg";
import { cn } from "src/utilities/utils";
import { useMessagingContext } from "src/context/MessagingContext";
import { useQuery } from "@tanstack/react-query";
import { getCreatorMessageRooms, getMessageRooms } from "src/api";
import { useSearchParams } from "react-router-dom";

const MessageRoomList = ({ creator }) => {
	const { isOpen } = useMessagingContext();

	const [search, setSearch] = useState("");

	return (
		<div
			className={cn(
				"mb-[2rem] mt-[2rem] px-[1.5rem] overflow-y-auto max-lg:shadow-2xl",
				"max-lg:absolute lg:left-0 transition-[left] bg-white z-10",
				isOpen ? "left-0" : "-left-full"
			)}
		>
			<CustomInput
				containerClassName="sticky top-0 bg-white"
				value={search}
				placeholder="Search..."
				onChange={(event) => setSearch(event.target.value)}
				icon={<SearchIcon />}
				type="text"
			/>
			<Suspense fallback={<Loader />}>
				<RenderArea creator={creator} search={search} setSearch={setSearch} />
			</Suspense>
		</div>
	);
};

const RenderArea = ({ creator, search, setSearch }) => {
	const { roomDetails, setRoomDetails, setFellowshipID, setApplicantID, setCreatorID } = useMessagingContext();
	const [searchParams, setSearchParams] = useSearchParams();

	const applicantSearch = searchParams.get("applicant_id");
	const creatorSearch = searchParams.get("creator_id");
	const fellowshipSearch = searchParams.get("fellowship_id");

	const { data } = useQuery({
		queryKey: [creator ? "creator-message-rooms" : "message-rooms", fellowshipSearch, creatorSearch, applicantSearch, search],
		queryFn: creator
			? () => getCreatorMessageRooms({ fellowship_id: fellowshipSearch, applicant_id: applicantSearch, search })
			: () => getMessageRooms({ fellowship_id: fellowshipSearch, creator_id: creatorSearch, search }),
		suspense: true,
	});

	const messageRooms = data?.data; //?.data;

	const clearFilter = () => {
		setSearch("");
		setSearchParams({});
	};

	useEffect(() => {
		if (!roomDetails && !!messageRooms) {
			const room = messageRooms[0];

			setRoomDetails();
			setFellowshipID(room?.fellowship?.id);
			setRoomDetails(room);

			if (creator) {
				setApplicantID(room?.applicant?.id);
			} else {
				setCreatorID(room?.creator?.id);
			}
		}
	}, [creator, messageRooms, roomDetails, setApplicantID, setCreatorID, setFellowshipID, setRoomDetails]);

	if (!messageRooms?.length) return <h1 className="mt-4">No rooms found...</h1>;

	return (
		<>
			{(fellowshipSearch || creatorSearch || applicantSearch || search) && (
				<button
					className="mt-2 text-[1.3rem] font-medium text-primary gap-2 ml-auto flex items-center hover:bg-gray-200 px-2 py-1 rounded-full"
					onClick={clearFilter}
				>
					<CloseIcon className="w-[15px] h-[15px] stroke-[3px]" /> Clear filters
				</button>
			)}
			<div className="h-px mt-9" />
			{messageRooms?.map((room, index) => (
				<MessageRoomButton key={index} room={room} creator={creator} />
			))}
		</>
	);
};

const MessageRoomButton = ({ room, creator }) => {
	const { setFellowshipID, setApplicantID, setCreatorID, setRoomDetails, roomDetails } = useMessagingContext();

	const isCurrentRoom =
		roomDetails?.fellowship_id === room.fellowship_id &&
		(creator ? roomDetails?.applicant_id === room?.applicant_id : roomDetails?.creator_id === room?.creator_id);

	const changeRoom = () => {
		setFellowshipID(room?.fellowship?.id);
		setRoomDetails(room);

		if (creator) {
			setApplicantID(room?.applicant?.id);
		} else {
			setCreatorID(room?.creator?.id);
		}
	};

	return (
		<button
			className={cn("flex items-center w-full gap-4 px-4 py-5 rounded-lg hover:bg-gray-200 mb-1", isCurrentRoom && "bg-gray-200")}
			onClick={changeRoom}
		>
			<div className="text-left">
				<p className="text-headerText text-[1.7rem] font-medium leading-none mb-1">
					{creator
						? `${room?.applicant?.first_name} ${room?.applicant?.last_name}`
						: `${room?.creator?.first_name} ${room?.creator?.last_name}`}
				</p>
				<p className="text-bodyText text-[1.4rem]">{room?.fellowship?.title}</p>
			</div>
		</button>
	);
};

export default MessageRoomList;
