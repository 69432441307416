import { ErrorMessage } from "formik";
import React, { useState } from "react";
import { EyeClosed, EyeOpen } from "src/assets/svg";

const CustomInput = ({ icon, label, containerClassName, labelClassName, innerContainerClassName, ...props }) => {
	const [isVisible, setIsVisible] = useState(false);

	if (props["type"] === "password")
		return (
			<div className={containerClassName}>
				{!!label && <label className={`mb-3 block font-medium text-[1.6rem] text-bodyText2 ${labelClassName}`}>{label}</label>}
				<div
					className={`flex items-center gap-4 border rounded-md border-lightGray px-8 py-4 text-[1.6rem] has-[:disabled]:bg-[#FBFBFB] has-[:focus]:border-primary min-w-[250px] ${innerContainerClassName}`}
				>
					{icon}
					<input
						{...props}
						type={isVisible ? "text" : "password"}
						className={`!border-0 focus:![--tw-ring-shadow:transparent] !outline-0 placeholder:text-[#a8b0bd] text-bodyText text-[1.6rem] w-full p-0 ${props["className"]}`}
					/>
					<button type="button" onClick={() => setIsVisible((prevState) => !prevState)}>
						{isVisible ? <EyeOpen className="w-8 text-bodyText" /> : <EyeClosed className="w-8 text-bodyText" />}
					</button>
				</div>
				{!!props["name"] && <ErrorMessage name={props["name"]} component="div" className="block mt-2 text-[1.2rem] text-red-500" />}
			</div>
		);

	return (
		<div className={containerClassName}>
			{!!label && <label className={`mb-3 block font-medium text-[1.6rem] text-bodyText2 ${labelClassName}`}>{label}</label>}
			<div
				className={`flex items-center gap-4 border rounded-md border-lightGray px-8 py-4 text-[1.6rem] has-[:disabled]:bg-[#FBFBFB] has-[:focus]:border-primary min-w-[250px] ${innerContainerClassName}`}
			>
				{icon}
				<input
					{...props}
					className={`!border-0 focus:![--tw-ring-shadow:transparent] !outline-0 placeholder:text-[#a8b0bd] text-bodyText text-[1.6rem] w-full p-0 ${props["className"]}`}
				/>
			</div>
			{!!props["name"] && <ErrorMessage name={props["name"]} component="div" className="block mt-2 text-[1.2rem] text-red-500" />}
		</div>
	);
};

export default CustomInput;
