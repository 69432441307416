import { Formik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import { FacebookIcon, LinkedinIcon, TwitterIcon } from "src/assets/svg";
import { CustomInput } from "src/components";
import * as Yup from "yup";

const Footer = () => {
	const initialValues = {
		email: "",
	};
	const validationSchema = Yup.object().shape({
		email: Yup.string().email("Please provide a valid email").required("This field is required"),
	});
	return (
		<footer className="[--bleed-color:#010D24] bg-[var(--bleed-color)] full-bleed py-24 text-white">
			<div className="flex max-[675px]:flex-col items-center justify-between gap-5 pb-12 border-b border-[#273142]">
				<div>
					<h2 className="font-Roobert font-bold text-[2.4rem] mb-2 max-[675px]:text-center">Join our newsletter</h2>
					<p className="text-[1.6rem] max-2xl::max-w-[315px] max-[675px]:text-center">
						Get all the latest Kohort news delivered to your inbox.
					</p>
				</div>
				<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => console.log(values)}>
					{({ values, handleSubmit, handleChange }) => (
						<form onSubmit={handleSubmit} className="flex max-[400px]:flex-col max-[400px]:w-full items-start gap-6">
							<CustomInput
								containerClassName="max-[400px]:w-full"
								innerContainerClassName="bg-white"
								placeholder="Email address"
								name="email"
								onChange={handleChange}
								value={values.email}
							/>
							<button className="rounded-lg max-[400px]:w-full primary-btn py-5" type="submit">
								Subscribe
							</button>
						</form>
					)}
				</Formik>
			</div>
			<div className="pt-12">
				<div className="flex flex-wrap items-center justify-between gap-10 mb-9">
					<h2 className="font-Roobert font-bold text-[2.8rem]">Kohort</h2>
					<ul className="flex items-center gap-5">
						<li>
							<Link to="#">
								<FacebookIcon />
							</Link>
						</li>
						<li>
							<Link to="#">
								<TwitterIcon />
							</Link>
						</li>
						<li>
							<Link to="#">
								<LinkedinIcon />
							</Link>
						</li>
					</ul>
				</div>
				<p className="text-[1.6rem] mb-9">© {new Date().getFullYear()} Kohort. All rights reserved.</p>
				<p className="text-[1.6rem] max-w-[420px] mb-2">Kohort is a product of Civic Innovation Centre</p>
				<p className="text-[1.6rem] max-w-[420px] mb-9">All trademarks, logos and brand names are the property of their respective owners.</p>
				<ul className="flex items-center gap-5">
					<li>
						<Link to="#">Terms of Service</Link>
					</li>
					<li>
						<Link to="#">Privacy Policy</Link>
					</li>
					<li>
						<Link to="/contact" className="block">
							Contact Us
						</Link>
					</li>
				</ul>
			</div>
		</footer>
	);
};

export default Footer;
