import { Formik } from "formik";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { CustomInput } from "src/components";
import { useAppContext } from "src/context/AppContext";
import * as Yup from "yup";

const Profile = () => {
	const { pathname, search } = useLocation();
	const { user, token } = useAppContext();

	const profileInitialValues = {
		first_name: user?.first_name,
		last_name: user?.last_name,
		email: user?.email,
	};
	const profileValidationSchema = Yup.object().shape({
		first_name: Yup.string().required("This field is required"),
		last_name: Yup.string().required("This field is required"),
		email: Yup.string().email("Please provide a valid email").required("This field is required"),
	});
	const passwordInitialValues = {
		currentPassword: "",
		newPassword: "",
		confirmPassword: "",
	};
	const passwordValidationSchema = Yup.object().shape({
		currentPassword: Yup.string().required("This field is required"),
		newPassword: Yup.string().required("This field is required"),
		confirmPassword: Yup.string().required("This field is required"),
	});

	if (!user || !token) return <Navigate to={{ pathname: "/login", search: `redirect=${encodeURIComponent(pathname.concat(search))}` }} />;

	return (
		<div className="pt-24 pb-56 max-w-[1000px]">
			<h1 className="text-headerText leading-[125%] font-Roobert text-[4.4rem] min-[800px]:text-[4.4rem] font-bold mb-6">Profile</h1>
			<Formik initialValues={profileInitialValues} validationSchema={profileValidationSchema}>
				{({ values, handleSubmit, handleChange, resetForm }) => (
					<>
						<p className="text-headerText text-[2rem] font-Roobert font-semibold mb-4 mt-12">Change Profile Information</p>
						<form onSubmit={handleSubmit} className="border grid gap-6 border-[#919BAF4D] rounded-[20px] px-[3rem] py-[3rem]">
							<div className="grid gap-6 grid-cols-[repeat(auto-fit,minmax(250px,1fr))]">
								<CustomInput
									label="First name"
									value={values.first_name}
									onChange={handleChange}
									placeholder="First name"
									name="first_name"
								/>
								<CustomInput
									label="Last name"
									value={values.last_name}
									onChange={handleChange}
									placeholder="Last name"
									name="last_name"
								/>
							</div>
							<CustomInput label="Email" value={values.email} disabled onChange={handleChange} placeholder="Email" name="email" />
							<div className="flex items-center">
								<button className="cancel-btn" type="button" onClick={() => resetForm()}>
									Cancel
								</button>
								<button
									className="py-3 px-9 primary-btn btn-loader"
									// disabled={isLoading}
								>
									Update Profile
								</button>
							</div>
						</form>
					</>
				)}
			</Formik>
			<Formik initialValues={passwordInitialValues} validationSchema={passwordValidationSchema}>
				{({ values, handleSubmit, handleChange, resetForm }) => (
					<>
						<p className="text-headerText text-[2rem] font-Roobert font-semibold mt-12">Change Password</p>
						<p className="text-bodyText2 text-[1.6rem] mb-6">Please enter your current password to change your password</p>
						<form onSubmit={handleSubmit} className="border grid gap-6 border-[#919BAF4D] rounded-[20px] px-[3rem] py-[3rem]">
							<CustomInput
								label="Current password"
								value={values.currentPassword}
								onChange={handleChange}
								placeholder="Current password"
								name="currentPassword"
								type="password"
							/>
							<CustomInput
								label="New password"
								value={values.newPassword}
								onChange={handleChange}
								placeholder="New password"
								name="newPassword"
								type="password"
							/>
							<CustomInput
								label="Confirm password"
								value={values.confirmPassword}
								onChange={handleChange}
								placeholder="Confirm password"
								name="confirmPassword"
								type="password"
							/>
							<div className="flex items-center">
								<button className="cancel-btn" type="button" onClick={() => resetForm()}>
									Cancel
								</button>
								<button
									className="py-3 px-9 primary-btn btn-loader"
									// disabled={isLoading}
								>
									Update Password
								</button>
							</div>
						</form>
					</>
				)}
			</Formik>
		</div>
	);
};

export default Profile;
