import React from "react";
import { Link } from "react-router-dom";
import { RightArrowIcon } from "src/assets/svg";
import sanitizeHtml from "sanitize-html";
import { sanitizeHtmlOption } from "src/utilities/constants";

const FeaturedCard = ({ fellowship }) => {
	return (
		<Link to={`/programs/${fellowship.uuid}`}>
			<div>
				<img src={fellowship?.cover_image} className="object-cover w-full max-h-[250px] aspect-[3/2] rounded-2xl" alt="featured-card" />
			</div>
			<div className="px-4 py-6">
				<p className="text-headerText text-[1.75rem] font-Roobert font-semibold mb-3">{fellowship?.title}</p>
				<div
					className="*:text-[1.4rem] *:leading-[1.5] *:text-bodyText mb-4 truncate-overflow [--max-lines:3] [--line-height:2.1rem]"
					dangerouslySetInnerHTML={{
						__html: sanitizeHtml(fellowship?.description, sanitizeHtmlOption),
					}}
				/>
				<span className="flex items-center gap-2 text-headerText text-[1.4rem] font-semibold">
					View Program <RightArrowIcon />
				</span>
			</div>
		</Link>
	);
};

export default FeaturedCard;
