import React, { forwardRef } from "react";
import DataTable from "react-data-table-component";
import { useSearchParams } from "react-router-dom";
import Loader from "./Loader";
import { Pagination } from ".";

const CustomDataTable = (props) => {
	const [searchParams, setSearchParams] = useSearchParams();

	return (
		<div className="table-responsive mb-[70px]">
			<DataTable
				pagination
				progressComponent={<Loader spin />}
				paginationServer
				paginationComponent={Pagination}
				paginationDefaultPage={searchParams.has("page") ? parseInt(searchParams.get("page")) : 1}
				paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40, 50, 100, 200]}
				paginationPerPage={searchParams.has("per_page") ? parseInt(searchParams.get("per_page")) : 50}
				onChangePage={(page) => {
					if (page !== 1) {
						searchParams.set("page", page);
						setSearchParams(searchParams);
					} else {
						searchParams.delete("page");
						setSearchParams(searchParams);
					}
				}}
				onChangeRowsPerPage={(currentRowsPerPage) => {
					if (currentRowsPerPage !== 50) {
						searchParams.set("per_page", currentRowsPerPage);
						setSearchParams(searchParams);
					} else {
						searchParams.delete("per_page");
						setSearchParams(searchParams);
					}
				}}
				customStyles={{
					table: {
						style: {
							border: props.data?.length ? "1px solid #EAEBF0" : "none",
							borderRadius: 8,
							paddingInline: "0",
						},
					},
					rows: {
						style: {
							fontSize: "1.4rem",
							fontWeight: 500,
							color: "var(--body-text-2)",
							letterSpacing: "-1%",
							paddingBlock: "1rem",
							"&>div:first-of-type": { paddingLeft: "2rem" },
							"&>div:last-of-type": { paddingRight: "2rem" },
						},
					},
					headRow: {
						style: {
							fontSize: "1.4rem",
							fontWeight: 600,
							letterSpacing: "-1%",
							color: "var(--body-text)",
							paddingBlock: "1rem",
							"&>div:first-of-type": { paddingLeft: "2rem" },
							"&>div:last-of-type": { paddingRight: "2rem" },
						},
					},
					subHeader: {
						style: {
							paddingInline: 0,
							paddingBlock: "1.5rem",
							borderTopLeftRadius: "10px",
							borderTopRightRadius: "10px",
						},
					},
				}}
				selectableRowsComponent={CheckBox}
				{...props}
			/>
		</div>
	);
};

const CheckBox = forwardRef((props, ref) => {
	return (
		<input
			ref={ref}
			type="checkbox"
			className="w-[17px] h-[18px] border-2 border-[#DAE0E6] cursor-pointer rounded-lg accent-primary text-primary mr-4"
			{...props}
		/>
	);
});

export default CustomDataTable;
