import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Formik, useFormikContext } from "formik";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { GalleryIcon } from "src/assets/svg";
import { CustomDatePicker, CustomEditor, CustomInput, CustomSelect, PopupSelect } from "src/components";
import { useAppContext } from "src/context/AppContext";
import { convertToEditorState, htmlHasText, renderSuccessMessage } from "src/utilities/functions";
import * as Yup from "yup";
import { format } from "date-fns";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createFellowship, editFellowship } from "src/api";
import { useModalContext } from "src/components/Modal";

const CreateFellowshipDetails = ({ setFellowshipId }) => {
	const { fellowshipTypes } = useAppContext();

	const initialValues = {
		cover_image: null,
		title: "",
		project_budget: "",
		description: convertToEditorState(""),
		fellowship_type_id: "",
		payment_frequency: "",
		level_of_expertise: "",
		skills_required: [],
		closing_date: new Date(),
	};
	const validationSchema = Yup.object().shape({
		title: Yup.string().max(45, "Title cannot be more than 45 characters long").required("This field is required"),
		description: Yup.mixed().test("is-empty", "This field is required", (value) =>
			htmlHasText(draftToHtml(convertToRaw(value.getCurrentContent())))
		),
		fellowship_type_id: Yup.string().required("This field is required"),
		payment_frequency: Yup.string()
			.oneOf(["milestone", "one-time"], "Payment Frequency must be one of Milestone or One Time")
			.required("This field is required"),
		level_of_expertise: Yup.string().required("This field is required"),
		closing_date: Yup.string().required("This field is required"),
		skills_required: Yup.array().of(Yup.string().required()).min(1, "This field is required"),
		project_budget: Yup.number()
			.min(0, "Minimum allowable budget is zero")
			.required("This field is required")
			.typeError("This field must be a number"),
	});

	const { mutate, isLoading } = useMutation(createFellowship, {
		onSuccess: ({ message, data }) => renderSuccessMessage(message).then(() => setFellowshipId(data?.uuid)),
	});

	return (
		<Formik
			validateOnBlur
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				const { closing_date, description } = values;
				const data = {
					...values,
					closing_date: format(closing_date, "yyyy-MM-dd"),
					description: draftToHtml(convertToRaw(description.getCurrentContent())),
				};
				mutate(data);
			}}
		>
			{({ values, handleSubmit, handleChange, setFieldValue, errors }) => (
				<form onSubmit={handleSubmit} className="grid gap-10">
					<Dropzone name="cover_image" />
					<CustomInput name="title" onChange={handleChange} value={values.title} placeholder="Program Title" />
					<CustomEditor setState={setFieldValue} name="description" state={values.description} errorState={!!errors?.description} />
					<PopupSelect
						value={values.fellowship_type_id}
						placeholder="Program Type"
						options={fellowshipTypes?.map(({ name, id, icon }) => ({
							text: (
								<div className="flex items-center gap-4">
									<img src={icon} className="w-[2rem] h-[2rem]" alt="" />
									{name}
								</div>
							),
							value: String(id),
						}))}
						name="fellowship_type_id"
					/>
					<CustomInput
						placeholder="Project Budget"
						name="project_budget"
						value={values.project_budget}
						onChange={handleChange}
						type="text"
						inputMode="numeric"
					/>
					<PopupSelect
						value={values.payment_frequency}
						placeholder="Payment Frequency"
						options={[
							{
								text: "Milestone",
								value: "milestone",
							},
							{
								text: "One Time",
								value: "one-time",
							},
						]}
						name="payment_frequency"
					/>
					<PopupSelect
						value={values.level_of_expertise}
						placeholder="Level of expertise"
						options={[
							{
								text: "Beginner",
								value: "beginner",
							},
							{
								text: "Intermediate",
								value: "intermediate",
							},
							{
								text: "Expert",
								value: "expert",
							},
						]}
						name="level_of_expertise"
					/>
					<CustomSelect
						name="skills_required"
						placeholder="Enter one or more skills"
						creatable
						isMulti
						value={values.skills_required.map((skill) => ({ label: skill, value: skill }))}
						onChange={(values) => {
							const skillSet = new Set(values.map((value) => value.value));
							setFieldValue("skills_required", [...Array.from(skillSet)]);
						}}
						formatCreateLabel={(inputValue) => `Add ${inputValue}`}
						onCreateOption={(value) => {
							const skillSet = new Set(values.skills_required);
							setFieldValue("skills_required", [...Array.from(skillSet.add(value))]);
						}}
						components={{ DropdownIndicator: null }}
						noOptionsMessage={() => null}
					/>
					<CustomDatePicker name="closing_date" selected={values.closing_date} onChange={(date) => setFieldValue("closing_date", date)} />
					<button className="py-4 ml-auto primary-btn btn-loader w-fit" disabled={isLoading}>
						Submit
					</button>
				</form>
			)}
		</Formik>
	);
};

export const EditFellowshipDetails = ({ fellowship }) => {
	const { fellowshipTypes } = useAppContext();
	const { setIsOpen } = useModalContext();
	const queryClient = useQueryClient();

	const initialValues = {
		fellowshipId: fellowship?.uuid,
		cover_image: fellowship?.cover_image,
		title: fellowship?.title,
		project_budget: fellowship?.project_budget,
		description: convertToEditorState(fellowship?.description),
		fellowship_type_id: fellowship?.fellowship_type_id,
		payment_frequency: fellowship?.payment_frequency,
		level_of_expertise: fellowship?.level_of_expertise,
		skills_required: fellowship?.skills_required,
		closing_date: new Date(fellowship?.closing_date || "2024-06-28"),
	};
	const validationSchema = Yup.object().shape({
		title: Yup.string().max(45, "Title cannot be more than 45 characters long").required("This field is required"),
		description: Yup.mixed().test("is-empty", "This field is required", (value) =>
			htmlHasText(draftToHtml(convertToRaw(value.getCurrentContent())))
		),
		fellowship_type_id: Yup.string().required("This field is required"),
		payment_frequency: Yup.string()
			.oneOf(["milestone", "one-time"], "Payment Frequency must be one of Milestone or One Time")
			.required("This field is required"),
		level_of_expertise: Yup.string().required("This field is required"),
		closing_date: Yup.string().required("This field is required"),
		skills_required: Yup.array().of(Yup.string().required()).min(1, "This field is required"),
		project_budget: Yup.number()
			.min(0, "Minimum allowable budget is zero")
			.required("This field is required")
			.typeError("This field must be a number"),
	});

	const { mutate, isLoading } = useMutation(editFellowship, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(async () => {
				await queryClient.invalidateQueries({ queryKey: ["creator-fellowship", fellowship?.uuid], type: "active" });
				setIsOpen(false);
			}),
	});

	return (
		<Formik
			validateOnBlur
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				const { closing_date, description } = values;
				const data = {
					...values,
					closing_date: format(closing_date, "yyyy-MM-dd"),
					description: draftToHtml(convertToRaw(description.getCurrentContent())),
				};
				mutate(data);
			}}
		>
			{({ values, handleSubmit, handleChange, setFieldValue, errors }) => (
				<form onSubmit={handleSubmit} className="grid gap-10">
					<Dropzone name="cover_image" />
					<CustomInput name="title" onChange={handleChange} value={values.title} placeholder="Program Title" />
					<CustomEditor setState={setFieldValue} name="description" state={values.description} errorState={!!errors?.description} />
					<PopupSelect
						value={values.fellowship_type_id}
						placeholder="Program Type"
						options={fellowshipTypes?.map(({ name, id, icon }) => ({
							text: (
								<div className="flex items-center gap-4">
									<img src={icon} className="w-[2rem] h-[2rem]" alt="" />
									{name}
								</div>
							),
							value: id,
						}))}
						name="fellowship_type_id"
					/>
					<CustomInput
						placeholder="Project Budget"
						name="project_budget"
						value={values.project_budget}
						onChange={handleChange}
						type="text"
						inputMode="numeric"
					/>
					<PopupSelect
						value={values.payment_frequency}
						placeholder="Payment Frequency"
						options={[
							{
								text: "Milestone",
								value: "milestone",
							},
							{
								text: "One Time",
								value: "one-time",
							},
						]}
						name="payment_frequency"
					/>
					<PopupSelect
						value={values.level_of_expertise}
						placeholder="Level of expertise"
						options={[
							{
								text: "Beginner",
								value: "beginner",
							},
							{
								text: "Intermediate",
								value: "intermediate",
							},
							{
								text: "Expert",
								value: "expert",
							},
						]}
						name="level_of_expertise"
					/>
					<CustomSelect
						name="skills_required"
						placeholder="Enter one or more skills"
						creatable
						isMulti
						value={values.skills_required.map((skill) => ({ label: skill, value: skill }))}
						onChange={(values) => {
							const skillSet = new Set(values.map((value) => value.value));
							setFieldValue("skills_required", [...Array.from(skillSet)]);
						}}
						formatCreateLabel={(inputValue) => `Add ${inputValue}`}
						onCreateOption={(value) => {
							const skillSet = new Set(values.skills_required);
							setFieldValue("skills_required", [...Array.from(skillSet.add(value))]);
						}}
						components={{ DropdownIndicator: null }}
						noOptionsMessage={() => null}
					/>
					<CustomDatePicker name="closing_date" selected={values.closing_date} onChange={(date) => setFieldValue("closing_date", date)} />
					<button className="py-4 ml-auto primary-btn btn-loader w-fit" disabled={isLoading}>
						Submit
					</button>
				</form>
			)}
		</Formik>
	);
};

const Dropzone = ({ name }) => {
	const { setFieldValue, values } = useFormikContext();

	const onDrop = useCallback(
		(acceptedFiles) => {
			const file = acceptedFiles[0];
			setFieldValue(name, Object.assign(file, { preview: URL.createObjectURL(file) }));
		},
		[name, setFieldValue]
	);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: {
			"icon/png": [".png", ".jpg", ".jpeg"],
		},
	});

	return (
		<div>
			<div
				className="border border-[919BAF1A] hover:bg-gray-200 transition-all rounded-xl mb-2 px-6 py-16 cursor-pointer flex items-center justify-center flex-col"
				{...getRootProps()}
			>
				<input {...getInputProps()} aria-label="input-file" />
				{!values[name] ? (
					<>
						<GalleryIcon className="text-[#BCC3CF] w-[45px] h-[45px]" />
						<p className="text-[1.2rem] text-bodyText font-medium max-w-[280px] text-center mb-6 mt-3">
							Drag and drop an icon or click the button to upload your cover icon
						</p>
						<button type="button" className="py-4 primary-btn">
							Upload Image
						</button>
					</>
				) : typeof values[name] === "string" ? (
					<img src={values[name]} className="object-contain max-h-[300px]" alt="" />
				) : (
					<img src={values[name]?.preview} className="object-contain max-h-[300px]" alt="" />
				)}
			</div>
			<ul className="flex items-center gap-x-8 gap-y-1 flex-wrap text-[1.4rem] list-inside list-disc text-bodyText">
				<li className="marker:!m-0">Maximum file size: 5MB</li>
				<li className="marker:!m-0">Supported image files: JPEG, JPG or PNG</li>
			</ul>
		</div>
	);
};

export default CreateFellowshipDetails;
