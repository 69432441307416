import { ErrorMessage, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { CustomTextArea } from "src/components";
import StarRatings from "react-star-ratings";
import { useModalContext } from "src/components/Modal";
import { useMutation } from "@tanstack/react-query";
import { rateApplicant } from "src/api";
import { renderSuccessMessage } from "src/utilities/functions";

const RateApplicant = ({ applicant, fellowship }) => {
	const { setIsOpen } = useModalContext();

	const initialValues = {
		applicant: applicant?.uuid,
		fellowship: fellowship,
		rating: 0,
		comment: "",
	};
	const validationSchema = Yup.object().shape({
		rating: Yup.number().positive("Please provide a rating").required("This field is required"),
		comment: Yup.string().max(1000, "Max length is 1000 characters"),
	});

	const { mutate, isLoading } = useMutation(rateApplicant, {
		onSuccess: ({ message }) => renderSuccessMessage(message).then(() => setIsOpen(false)),
	});

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values, setFieldValue, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="grid gap-4">
					<h1 className="text-center text-[#344054] text-[1.8rem] font-Roobert font-semibold">Please Leave a Rating</h1>
					<div style={{ justifySelf: "center" }}>
						<StarRatings
							rating={values.rating}
							changeRating={(rate) => setFieldValue("rating", rate)}
							numberOfStars={5}
							starDimension="40px"
							starSpacing="4px"
							starRatedColor="#FBBF24"
							starHoverColor="#FBBF24"
						/>
					</div>
					<CustomTextArea
						name="comment"
						onChange={handleChange}
						value={values.comment}
						placeholder="Tell us why you left this rating"
						rows={6}
						className="py-6 placeholder:!text-[1.4rem] !text-[1.4rem]"
					>
						<p className="ml-auto text-bodyText text-[1.2rem]">{1000 - values.comment.length} characters left</p>
					</CustomTextArea>
					<ErrorMessage name="rating" component="div" className="text-red-500 mt-4 text-center text-[1rem]" />

					<div className="flex items-center justify-end mt-6">
						<button className="cancel-btn" type="button" onClick={() => setIsOpen(false)}>
							Cancel
						</button>
						<button className="py-4 primary-btn btn-loader" disabled={isLoading}>
							Submit
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default RateApplicant;
