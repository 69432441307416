import React, { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { NoUserIcon } from "src/assets/svg";
import { BreadCrumbs, CustomDataTable, EmptyState, Modal } from "src/components";
import { format } from "date-fns";
import { ApplicantDetails, GradeApplicant } from "src/modals";
import { useParams, useSearchParams } from "react-router-dom";
import { judgeFellowshipApplicants, judgeFellowshipGradedApplicants } from "src/api";
import { useQuery } from "@tanstack/react-query";

const tabs = { applicants: 0, graded: 1, selected: 2 };

const FellowshipApplicants = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	return (
		<div>
			<BreadCrumbs />
			<div className="flex flex-wrap items-center justify-between mb-10">
				<h1 className="dashboard-header">Applicants</h1>
			</div>
			<Tabs
				selectedTabClassName="text-primary border-b border-primary"
				defaultIndex={searchParams.has("tab") ? tabs[searchParams.get("tab")] : 0}
				onSelect={(_, __, event) => setSearchParams({ tab: event.target.dataset.id })}
			>
				<TabList className="flex gap-10 mb-14">
					<Tab data-id="applicants" className="pb-4 cursor-pointer font-medium text-[1.5rem] text-bodyText">
						Applicants
					</Tab>
					<Tab data-id="graded" className="pb-4 cursor-pointer font-medium text-[1.5rem] text-bodyText">
						Graded
					</Tab>
				</TabList>
				<TabPanel>
					<Applicants />
				</TabPanel>
				<TabPanel>
					<Graded />
				</TabPanel>
			</Tabs>
		</div>
	);
};

const Applicants = () => {
	const { id } = useParams();

	const { data } = useQuery({
		queryKey: ["judge-fellowship-applicants", id],
		queryFn: () => judgeFellowshipApplicants(id),
		suspense: true,
	});
	const columns = [
		{
			name: "Name & Email",
			cell: (row) => (
				<div className="overflow-hidden">
					<p data-tag="allowRowEvents" className="text-headerText">
						{row?.first_name} {row?.last_name}
					</p>
					<p data-tag="allowRowEvents" className="mt-1 lowercase truncate text-bodyText">
						{row?.email}
					</p>
				</div>
			),
		},
		{
			name: "Date Applied",
			selector: (row) => format(row?.date_applied || "2024-06-28", "dd-MMM-yyyy"),
		},
		{
			name: "Program",
			selector: (row) => row?.fellowship_name,
			style: { color: "var(--header-text)" },
		},
		{
			name: "",
			cell: (row) => <ActionButton applicant={row} />,
			width: "150px",
		},
	];

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [row, setRow] = useState(null);

	const applicants = data?.data?.data;

	return (
		<>
			<CustomDataTable
				columns={columns}
				data={applicants}
				highlightOnHover
				pointerOnHover
				onRowClicked={(row) => {
					setRow(row);
					setIsModalOpen(true);
				}}
				noDataComponent={
					<EmptyState icon={<NoUserIcon />}>
						<p className="font-Roobert font-medium text-[1.6rem] text-center text-bodyText2">
							This program has no applicants assigned to you
						</p>
					</EmptyState>
				}
			/>
			<Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} title="" size="l">
				<ApplicantDetails application={row} />
			</Modal>
		</>
	);
};

const ActionButton = ({ applicant }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			<button className="py-3 font-medium text-primary" onClick={() => setIsModalOpen(true)}>
				Grade Applicant
			</button>
			<Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} title="Grade Applicant" size="sm">
				<GradeApplicant applicant={applicant} />
			</Modal>
		</>
	);
};

const Graded = () => {
	const { id } = useParams();

	const { data } = useQuery({
		queryKey: ["judge-fellowship-graded-applicants", id],
		queryFn: () => judgeFellowshipGradedApplicants(id),
		suspense: true,
	});
	const columns = [
		{
			name: "Name & Email",
			cell: (row) => (
				<div className="overflow-hidden">
					<p data-tag="allowRowEvents" className="text-headerText">
						{row?.first_name} {row?.last_name}
					</p>
					<p data-tag="allowRowEvents" className="mt-1 lowercase truncate text-bodyText">
						{row?.email}
					</p>
				</div>
			),
		},
		{
			name: "Date Applied",
			selector: (row) => format(row?.date_applied || "2024-06-28", "dd-MMM-yyyy"),
		},
		{
			name: "Program",
			selector: (row) => row?.fellowship_name,
			style: { color: "var(--header-text)" },
		},
		{
			name: "Grade",
			selector: (row) => row.average_grade,
			width: "200px",
		},
		{
			name: "",
			cell: (row) => <GradedActionButton applicant={row} />,
			width: "150px",
		},
	];

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [row, setRow] = useState(null);

	const applicants = data?.data?.data;

	return (
		<>
			<CustomDataTable
				columns={columns}
				data={applicants}
				highlightOnHover
				pointerOnHover
				onRowClicked={(row) => {
					setRow(row);
					setIsModalOpen(true);
				}}
				noDataComponent={
					<EmptyState icon={<NoUserIcon />}>
						<p className="font-Roobert font-medium text-[1.6rem] text-center text-bodyText2">This program has no graded applicants</p>
					</EmptyState>
				}
			/>
			<Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} title="" size="l">
				<ApplicantDetails application={row} />
			</Modal>
		</>
	);
};

const GradedActionButton = ({ applicant }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			<button className="py-3 font-medium text-primary" onClick={() => setIsModalOpen(true)}>
				Update Grade
			</button>
			<Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} title="Update Applicant Grade" size="sm">
				<GradeApplicant applicant={applicant} />
			</Modal>
		</>
	);
};

export default FellowshipApplicants;
