import axios from "axios";
import { getFromStorage, removeKeysFromStorage, renderErrorMessage } from "./utilities/functions";

const authApi = axios.create({
	baseURL: process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_LOCAL_BASEURL}/auth` : `${process.env.REACT_APP_PROD_BASEURL}/auth`,
	headers: {
		"Content-Type": "application/json",
	},
});

authApi.interceptors.response.use(
	(response) => response.data,
	(error) => {
		renderErrorMessage(error.response.data.message);
		throw new Error(error.response.data.message);
	}
);

export const register = (data) => authApi.post("/register", data);

export const completeProfile = (data) => authApi.post("/complete-profile", data);

export const verifyToken = (params) => authApi.get("/verify-email", { params });

export const login = (data) => authApi.post("/login", data);

export const forgotPassword = (data) => authApi.post("/forgot-password", data);

export const resetPassword = (data) => authApi.post("/reset-password", data);

//
// ? APPLICANT ENDPOINTS
//

const api = axios.create({
	baseURL: process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_LOCAL_BASEURL}` : `${process.env.REACT_APP_PROD_BASEURL}`,
	headers: {
		"Content-Type": "application/json",
	},
});

api.interceptors.request.use((config) => {
	const token = getFromStorage("token");
	if (!!token) {
		config.headers["Authorization"] = `Bearer ${getFromStorage("token")}`;
	}
	return config;
}, null);

api.interceptors.response.use(
	(response) => response.data,
	(error) => {
		if (error.response.status === 401) {
			renderErrorMessage(error.response.data.message).then(() => {
				removeKeysFromStorage(["user", "token"]);
				window.location.reload();
			});
		} else {
			if (error.config.url.endsWith("/get-engagement")) return null;
			renderErrorMessage(error.response.data.message);
			throw new Error(error.response.data.message);
		}
	}
);

export const getFellowshipTypes = () => api.get("/fellowship-types");

export const getFormFields = () => api.get("/form-fields");

export const getFellowships = (params) => api.get("/fellowships", { params });

export const getFellowship = (id) => api.get(`/fellowships/${id}`);

export const deleteFellowship = ({ id }) => api.delete(`/fellowships/${id}`);

export const createFellowship = (data) => api.post("/fellowships", data, { headers: { "Content-Type": "multipart/form-data" } });

export const editFellowship = ({ fellowshipId, ...data }) =>
	api.post(`/fellowships/${fellowshipId}`, { ...data, _method: "PATCH" }, { headers: { "Content-Type": "multipart/form-data" } });

export const createApplicationForm = ({ fellowshipId, ...data }) => api.post(`/fellowships/${fellowshipId}/application-form`, data);

export const editApplicationForm = ({ fellowshipId, ...data }) => api.post(`/fellowships/${fellowshipId}/application-form`, data);

export const getApplicationForm = (id) => api.get(`/fellowships/${id}/application-form`);

export const respondToApplicationForm = ({ id, ...data }) =>
	api.post(`/fellowships/${id}/application-form/response`, data, { headers: { "Content-Type": "multipart/form-data" } });

export const getApplicantFellowships = (params) => api.get("/user/fellowship-dashboard", { params });

export const getApplicantFellowship = (id) => api.get(`/user/fellowships/${id}`);

export const submitProposal = ({ fellowshipId, ...data }) =>
	api.post(`/user/fellowships/${fellowshipId}/upload-proposal`, data, { headers: { "Content-Type": "multipart/form-data" } });

export const getSubmittedProposals = (fellowship) => api.get(`/user/fellowships/${fellowship}/submitted-proposals`);

export const getEngagementDetails = (fellowship) => api.get(`/user/fellowships/${fellowship}/get-engagement`);

export const setEngagementStatus = ({ fellowship, engagement, ...data }) =>
	api.patch(`/user/fellowships/${fellowship}/engagements/${engagement}`, data);

export const markMilestoneAsInProgress = ({ fellowship, engagement, milestone }) =>
	api.post(`/user/fellowships/${fellowship}/engagements/${engagement}/milestones/${milestone}/mark-in-progress`);

export const submitMilestone = ({ fellowship, engagement, milestone, ...data }) =>
	api.post(`/user/fellowships/${fellowship}/engagements/${engagement}/milestones/${milestone}`, data, {
		headers: { "Content-Type": "multipart/form-data" },
	});

export const rateCreator = ({ fellowship, creator, ...data }) => api.post(`/user/fellowships/${fellowship}/creator/${creator}/rate`, data);

export const getRatings = (uuid, params) => api.get(`/user-rating/${uuid}?role=applicant`, { params });

export const getMessageRooms = (params) => api.get("/user/messages", { params });

export const getMessages = (params) => api.get("/user/chat-messages", { params });

export const sendMessage = (data) => api.post("/user/send-message", data);

//
// ? CREATOR ENDPOINTS
//

export const creatorDashboard = () => api.get("/creator/dashboard");

export const creatorFellowships = (params) => api.get("/creator/fellowship-dashboard", { params });

export const getCreatorFellowships = (params) => api.get("/creator/fellowships", { params });

export const fellowshipJudges = ({ fellowship, ...params }) => api.get(`/creator/fellowships/${fellowship}/judges`, { params });

export const fellowshipApplicants = (fellowship) => api.get(`/creator/fellowships/${fellowship}/applicants`);

export const fellowshipGradedApplicants = (fellowship) => api.get(`/creator/fellowships/${fellowship}/graded-applicants`);

export const fellowshipSelectedApplicants = (fellowship) => api.get(`/creator/fellowships/${fellowship}/selected-applicants`);

export const fellowshipEngagedApplicants = (fellowship) => api.get(`/creator/fellowships/${fellowship}/engaged-applicant`);

export const fellowshipApplicantResponse = (fellowship, applicant) => api.get(`/fellowships/${fellowship}/applicants/${applicant}`);

export const creatorFellowshipDetails = (fellowshipId) => api.get(`/creator/fellowships/${fellowshipId}`);

export const inviteJudge = ({ fellowship, ...data }) => api.post(`/fellowships/${fellowship}/invite-judge`, data);

export const assignApplicantToJudge = ({ fellowship, ...data }) => api.post(`/creator/fellowships/${fellowship}/assign-to-judge`, data);

export const acceptInvitation = ({ fellowship, ...data }) => api.post(`/fellowships/${fellowship}/add-judge`, data);

export const getJudges = () => api.get(`/creator/judges-dashboard`);

export const selectApplicant = ({ fellowship, ...data }) => api.post(`/creator/fellowships/${fellowship}/select-applicant`, data);

export const unSelectApplicant = ({ fellowship, ...data }) => api.post(`/creator/fellowships/${fellowship}/unselect-applicant`, data);

export const getFellowshipSubmittedProposals = (fellowship, applicant) =>
	api.get(`/creator/fellowships/${fellowship}/selected-applicants/${applicant}/proposal`);

export const engageApplicant = ({ fellowship, ...data }) => api.post(`/creator/fellowships/${fellowship}/engage-applicant`, data);

export const setMilestoneStatus = ({ fellowship, engagement, milestone, ...data }) =>
	api.patch(`/creator/fellowships/${fellowship}/engagements/${engagement}/milestones/${milestone}`, data);

export const editEngagementTerms = ({ fellowship, engagement, ...data }) =>
	api.patch(`/creator/fellowships/${fellowship}/engagements/${engagement}`, data);

export const deleteMilestone = ({ fellowship, engagement, milestone }) =>
	api.delete(`/creator/fellowships/${fellowship}/engagements/${engagement}/milestones/${milestone}`);

export const closeFellowship = ({ fellowship }) => api.post(`/creator/fellowships/${fellowship}/close`);

export const rateApplicant = ({ fellowship, applicant, ...data }) => api.post(`/creator/fellowships/${fellowship}/applicant/${applicant}/rate`, data);

export const getCreatorMessageRooms = (params) => api.get("/creator/messages", { params });

export const getCreatorMessages = (params) => api.get("/creator/chat-messages", { params });

export const sendCreatorMessage = (data) => api.post("/creator/send-message", data);

//
// ? JUDGES ENDPOINTS
//

export const judgeFellowships = (params) => api.get("/judge/fellowship-dashboard", { params });

export const judgeFellowshipDetails = (fellowshipId) => api.get(`/judge/fellowships/${fellowshipId}`);

export const judgeFellowshipApplicants = (fellowship) => api.get(`/judge/fellowships/${fellowship}/applicants`);

export const judgeFellowshipGradedApplicants = (fellowship) => api.get(`/judge/fellowships/${fellowship}/graded-applicants`);

export const gradeApplicant = ({ fellowship, ...data }) => api.post(`/judge/fellowships/${fellowship}/grade-applicant`, data);

export const getNotifications = (unread = false) => api.get("/notifications", { params: { ...(unread ? { unread } : {}) } });

export const markNotificationAsRead = ({ notification }) => api.post(`/notifications/${notification}`);
