import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { completeProfile } from "src/api";
import { EmailIcon } from "src/assets/svg";
import { CustomInput } from "src/components";
import { useAppContext } from "src/context/AppContext";
import { renderSuccessMessage } from "src/utilities/functions";
import * as Yup from "yup";

const FinalizeRegister = () => {
	const { setToken, setUser } = useAppContext();
	const { state } = useLocation();

	const email = state.email;

	const initialValues = {
		first_name: "",
		last_name: "",
		profession: "",
		email: email,
		password: "",
		password_confirmation: "",
		phone_number: "",
	};
	const validationSchema = Yup.object().shape({
		first_name: Yup.string().required("This field is required"),
		last_name: Yup.string().required("This field is required"),
		profession: Yup.string().required("This field is required"),
		email: Yup.string().email("Please provide a valid email").required("This field is required"),
		password: Yup.string().required("This field is required"),
		password_confirmation: Yup.string()
			.oneOf([Yup.ref("password"), null], "Passwords must match")
			.required("This field is required"),
		phone_number: Yup.string().required("This field is required"),
	});

	const { mutate, isLoading } = useMutation(completeProfile, {
		onSuccess: ({ message, data }) =>
			renderSuccessMessage(message).then(() => {
				setToken(data.access_token);
				setUser(data.user);
			}),
	});

	return (
		<div className="grid grid-rows-[max-content,1fr] min-h-screen p-14">
			<h1 className="font-Roobert font-bold text-[2.81rem] text-[#1F1D1D] mb-20">Kohort</h1>
			<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
				{({ values, handleChange, handleSubmit }) => (
					<form className="w-full mx-auto my-auto max-w-[650px]" onSubmit={handleSubmit}>
						<h1 className="text-headerText text-[4rem] font-bold leading-[1.1] text-center mb-6">Welcome to Kohort</h1>
						<p className="max-w-[500px] mx-auto text-bodyText text-[1.4rem] text-center mb-20">
							Kohort is an online platform where individuals and organisations can come together to collaborate, apply for
							opportunities, etc.{" "}
						</p>
						<CustomInput
							label="Work Email"
							value={values.email}
							onChange={handleChange}
							placeholder="Email"
							name="email"
							disabled
							innerContainerClassName="!px-6"
							icon={<EmailIcon className="text-bodyText" />}
							containerClassName="mb-6"
						/>
						<div className="grid gap-6 mb-6 sm:grid-cols-2">
							<CustomInput
								label="First Name"
								value={values.first_name}
								onChange={handleChange}
								placeholder="First Name"
								name="first_name"
								innerContainerClassName="!px-6"
							/>
							<CustomInput
								label="Last Name"
								value={values.last_name}
								onChange={handleChange}
								placeholder="Last Name"
								name="last_name"
								innerContainerClassName="!px-6"
							/>
						</div>
						<CustomInput
							label="Profession"
							value={values.profession}
							onChange={handleChange}
							placeholder="Profession"
							name="profession"
							innerContainerClassName="!px-6"
							containerClassName="mb-6"
						/>
						<CustomInput
							label="Phone Number"
							value={values.phone_number}
							onChange={handleChange}
							placeholder="Phone number"
							name="phone_number"
							innerContainerClassName="!px-6"
							containerClassName="mb-6"
						/>
						<CustomInput
							label="Password"
							type="password"
							value={values.password}
							onChange={handleChange}
							placeholder="Password"
							name="password"
							innerContainerClassName="!px-6"
							containerClassName="mb-6"
						/>
						<CustomInput
							label="Confirm Password"
							type="password"
							value={values.password_confirmation}
							onChange={handleChange}
							placeholder="Confirm Password"
							name="password_confirmation"
							innerContainerClassName="!px-6"
							containerClassName="mb-14"
						/>
						<button className="w-full py-5 primary-btn btn-loader mb-14" disabled={isLoading}>
							Sign Up
						</button>
						<p className="text-[1.6rem] text-black text-center">
							Already have an account?
							<Link to="/login" className="font-medium text-primary hover:underline">
								{" "}
								Sign In
							</Link>
						</p>
					</form>
				)}
			</Formik>
		</div>
	);
};

export default FinalizeRegister;
