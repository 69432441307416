import { Formik } from "formik";
import React from "react";
import { CustomInput, CustomTextArea } from "src/components";
import * as Yup from "yup";

const Contact = () => {
	const initialValues = {
		name: "",
		email: "",
		phone: "",
		message: "",
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required("This field is required"),
		email: Yup.string().email("Please provide a valid email").required("This field is required"),
		phone: Yup.string().required("This field is required"),
		message: Yup.string().required("This field is required"),
	});
	return (
		<div className="grid pt-20 pb-52">
			<div className="grid grid-cols-[1fr_max-content] max-[750px]:grid-cols-1 gap-16 w-full max-w-[1400px] ml-auto">
				<div>
					<h1 className="text-headerText leading-[125%] font-Roobert text-[4.4rem] min-[800px]:text-[5.6rem] font-bold mb-6">Contact Us</h1>
					<p className="font-medium text-[1.6rem] text-bodyText max-w-[450px]">
						Feel free to reach out to use with any questions comments or concerns.
					</p>
					<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => console.log(values)}>
						{({ values, handleChange, handleSubmit, setFieldValue }) => (
							<form onSubmit={handleSubmit} className="grid gap-8 mt-16">
								<CustomInput name="name" onChange={handleChange} value={values.name} placeholder="Your full name" label="Full Name" />
								<CustomInput
									name="email"
									onChange={handleChange}
									value={values.email}
									placeholder="Your email address"
									label="Email Address"
								/>
								<CustomInput
									name="phone"
									onChange={handleChange}
									value={values.phone}
									placeholder="Your phone number"
									label="Phone Number"
								/>
								<CustomTextArea
									name="message"
									onChange={(event) => setFieldValue("message", event.target.value)}
									value={values.message}
									placeholder="Your Message"
									label="Message"
								/>
								<button className="w-full primary-btn">Send</button>
							</form>
						)}
					</Formik>
				</div>
				<div className="min-[650px]:max-w-[400px]">
					<p className="text-bodyText text-[1.2rem] font-medium uppercase">Call US</p>
					<p className="text-headerText text-[1.8rem] font-medium">+234-705-394-4592, +234-012914298</p>
					<p className="text-bodyText text-[1.2rem] font-medium uppercase mt-6">EMAIL US</p>
					<p className="text-headerText text-[1.8rem] font-medium">help@ourkohort.com</p>
					<p className="text-bodyText text-[1.2rem] font-medium uppercase mt-6">ADDRESS</p>
					<p className="text-headerText text-[1.8rem] font-medium">12 Street Road Avenue, Ikeja, Lagos</p>
				</div>
			</div>
		</div>
	);
};

export default Contact;
