import { useMutation } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { acceptInvitation } from "src/api";
import { Loader } from "src/components";
import { useAppContext } from "src/context/AppContext";
import { renderSuccessMessage } from "src/utilities/functions";

const AcceptInvitation = () => {
	const [searchParams] = useSearchParams();
	const { pathname, search } = useLocation();
	const { user, token } = useAppContext();
	const navigate = useNavigate();

	const { mutate } = useMutation(acceptInvitation, {
		onSuccess: ({ message }) => renderSuccessMessage(message).then(() => navigate("/judge")),
	});

	useEffect(() => {
		if (!searchParams.has("fellowshipId") || !searchParams.has("token")) {
			navigate(-1);
		}

		mutate({ fellowship: searchParams.get("fellowshipId"), token: searchParams.get("token") });
	}, [mutate, navigate, searchParams]);

	if (!user || !token) return <Navigate to={{ pathname: "/login", search: `redirect=${encodeURIComponent(pathname.concat(search))}` }} />;

	return (
		<div className="grid h-screen place-items-center">
			<Loader width="100px" style={{ borderWidth: 3 }} />
		</div>
	);
};

export default AcceptInvitation;
