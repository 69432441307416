import { useQuery } from "@tanstack/react-query";
import React from "react";
import StarRatings from "react-star-ratings";
import { getRatings } from "src/api";
import avatar from "src/assets/avatar.png";

const UserReviews = ({ user, role = "applicant", children }) => {
	const { data } = useQuery({
		queryKey: ["ratings", user],
		queryFn: () => getRatings(user, { role }),
		suspense: true,
	});

	const reviews = data?.data?.reviews;
	const avgRating = data?.data?.AvgRating;
	const totalRatings = data?.data?.totalRatings;

	return (
		<div>
			{children(
				<div>
					<div className="flex flex-wrap pt-5 overflow-scroll gap-x-10 gap-y-4">
						<p className="flex gap-2 text-bodyText font-medium text-[1.6rem]">Average Rating: {avgRating}</p>
						<p className="flex gap-2 text-bodyText font-medium text-[1.6rem]">Number of Review: {totalRatings}</p>
					</div>
				</div>
			)}
			{reviews?.map((review, index) => (
				<Review review={review} key={index} />
			))}
		</div>
	);
};

const Review = ({ review }) => {
	return (
		<div className="grid gap-2">
			<div className="flex items-center gap-4">
				<img src={avatar} alt="" className="w-[35px] aspect-square rounded-full border border-gray-400" />
				<p className="text-headerText text-[1.6rem] font-medium">
					{review?.user_rater_first_name} {review?.user_rater_last_name}
				</p>
			</div>
			<StarRatings
				rating={review?.rating}
				numberOfStars={5}
				starDimension="20px"
				starSpacing="2px"
				starRatedColor="#FBBF24"
				starHoverColor="#FBBF24"
			/>
			<p>{review?.comment}</p>
		</div>
	);
};

export default UserReviews;
