import React from "react";

const EmptyState = ({ icon, children, className }) => {
	return (
		<div className={`flex items-center justify-center min-h-[50vh] ${className}`}>
			<div className="grid *:mb-5 justify-center *:mx-auto max-w-[450px] mx-auto">
				{icon}
				{children}
			</div>
		</div>
	);
};

export default EmptyState;
