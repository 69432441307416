import { createContext, useContext, useState } from "react";

const MessagingContext = createContext({
	isOpen: false,
	setIsOpen: () => {},
	fellowshipID: "",
	setFellowshipID: () => {},
	applicantID: "",
	setApplicantID: () => {},
	creatorID: "",
	setCreatorID: () => {},
	roomDetails: "",
	setRoomDetails: () => {},
});

const MessagingContextProvider = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [fellowshipID, setFellowshipID] = useState("");
	const [applicantID, setApplicantID] = useState("");
	const [creatorID, setCreatorID] = useState("");
	const [roomDetails, setRoomDetails] = useState(null);

	return (
		<MessagingContext.Provider
			value={{
				isOpen,
				setIsOpen,
				fellowshipID,
				setFellowshipID,
				applicantID,
				setApplicantID,
				creatorID,
				setCreatorID,
				roomDetails,
				setRoomDetails,
			}}
		>
			{children}
		</MessagingContext.Provider>
	);
};

export default MessagingContextProvider;

export const useMessagingContext = () => {
	const context = useContext(MessagingContext);

	if (!context) throw new Error("`useMessagingContext` must be used within a `MessagingContextProvider`");

	return context;
};
