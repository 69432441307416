import { useMutation, useQuery } from "@tanstack/react-query";
import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { getNotifications, markNotificationAsRead } from "src/api";
import { CloseIcon, NoNotificationsIcon, RightArrowIcon } from "src/assets/svg";
import { EmptyState, Loader } from "src/components";
import { useAppContext } from "src/context/AppContext";
import { getInitials, renderSuccessMessage } from "src/utilities/functions";

const Notifications = () => {
	return (
		<div className="grid gap-2 min-w-[250px] md:max-w-[400px] max-w-[320px]">
			<p className="text-headerText font-Roobert text-[1.6rem] px-8 border-b border-[#F1F2F2] pt-8 pb-4 mb-2 font-semibold">Notifications</p>
			<Suspense fallback={<Loader />}>
				<RenderArea />
			</Suspense>
			<Link
				to="/notifications"
				className="font-Roobert font-semibold text-[1.45rem] flex items-center gap-3 text-headerText text-left px-8 py-6 border-t border-[#F1F2F2]"
			>
				See all notifications <RightArrowIcon />
			</Link>
		</div>
	);
};

const RenderArea = () => {
	const { user } = useAppContext();

	const { data, refetch } = useQuery({
		queryKey: ["notifications", true],
		queryFn: () => getNotifications(true),
		suspense: true,
	});

	const notifications = data?.data?.data;

	const { mutate } = useMutation(markNotificationAsRead, {
		onSuccess: ({ message }) => {
			refetch();
			renderSuccessMessage(message);
		},
	});

	if (!notifications?.length)
		return (
			<EmptyState className="!min-h-[300px] px-12" icon={<NoNotificationsIcon />}>
				<p className="font-Roobert font-medium text-[1.6rem] text-center text-bodyText2">You don't have any notifications</p>
			</EmptyState>
		);

	return notifications?.map((notification, index) => (
		<div className="py-[1.6rem] px-8 hover:bg-[#F1F4F6] flex gap-4 items-start" key={index}>
			<div className="bg-headerText px-2 aspect-square rounded-full text-[1.8rem] font-semibold text-white max-md:hidden flex items-center justify-center">
				{getInitials(user?.first_name, user?.last_name)}
			</div>
			<p className="font-normal text-[1.4rem] text-bodyText leading-[1.8] !p-0 truncate-overflow [--max-lines:4] [--line-height:2.38rem]">
				{notification?.message}
			</p>
			<button onClick={() => mutate({ notification: notification.uuid })} className="p-2 rounded-full hover:bg-gray-300">
				<CloseIcon className="w-[17px] h-[17px]" />
			</button>
		</div>
	));
};

export default Notifications;
