import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { forgotPassword } from "src/api";
import { EmailIcon } from "src/assets/svg";
import { CustomInput } from "src/components";
import { renderSuccessMessage } from "src/utilities/functions";
import * as Yup from "yup";

const ResetPassword = () => {
	const initialValues = {
		email: "",
	};
	const validationSchema = Yup.object().shape({
		email: Yup.string().email("Please provide a valid email").required("This field is required"),
	});

	const { mutate, isLoading } = useMutation(forgotPassword, {
		onSuccess: ({ message }) => renderSuccessMessage(message),
	});

	return (
		<div className="grid grid-rows-[max-content,1fr] min-h-screen p-14">
			<h1 className="font-Roobert font-bold text-[2.81rem] text-[#1F1D1D] mb-20">Kohort</h1>
			<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
				{({ values, handleChange, handleSubmit }) => (
					<form className="mx-auto my-auto" onSubmit={handleSubmit}>
						<h1 className="text-headerText text-[4rem] font-bold leading-[1.1] text-center mb-6">Reset Password</h1>
						<p className="max-w-[500px] text-bodyText text-[1.4rem] text-center mb-20">
							Enter your email address below to receive a link to reset your password.
						</p>
						<CustomInput
							label="Work Email"
							value={values.email}
							onChange={handleChange}
							placeholder="Email"
							name="email"
							innerContainerClassName="!px-6"
							icon={<EmailIcon className="text-bodyText" />}
							containerClassName="mb-6"
						/>
						<button className="w-full py-5 primary-btn btn-loader mb-14" disabled={isLoading}>
							Send email
						</button>
					</form>
				)}
			</Formik>
		</div>
	);
};

export default ResetPassword;
