import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { getCreatorFellowships, inviteJudge } from "src/api";
import { CustomSelect } from "src/components";
import { useModalContext } from "src/components/Modal";
import { renderSuccessMessage } from "src/utilities/functions";
import * as Yup from "yup";

const loadOptions = async (inputValue) => {
	if (!inputValue) return;

	const data = (await getCreatorFellowships({ q: inputValue })).data.map((fellowship) => ({
		value: fellowship.uuid,
		label: fellowship.title,
	}));
	return data;
};

const InviteJudge = ({ selectedFellowship }) => {
	const { setIsOpen } = useModalContext();

	const initialValues = {
		email: [],
		fellowship: !!selectedFellowship ? selectedFellowship?.id : null,
	};
	const validationSchema = Yup.object().shape({
		email: Yup.array().of(Yup.string().email("An invalid email was provided")).min(1, "At least 1 email must be provided"),
		fellowship: Yup.string().required("Please provide a program"),
	});

	const { mutate, isLoading } = useMutation(inviteJudge, {
		onSuccess: ({ message }) => renderSuccessMessage(message).then(() => setIsOpen(false)),
	});

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values, handleSubmit, setFieldValue }) => (
				<form className="grid gap-10" onSubmit={handleSubmit}>
					<CustomSelect
						label="Invite with email"
						name="email"
						placeholder="Enter one or more email addresses"
						creatable
						isMulti
						value={values.email.map((email) => ({ label: email, value: email }))}
						onChange={(values) => {
							const emailSet = new Set(values.map((value) => value.value));
							setFieldValue("email", [...Array.from(emailSet)]);
						}}
						formatCreateLabel={(inputValue) => `Invite ${inputValue}`}
						onCreateOption={(value) => {
							const emailSet = new Set(values.email);
							setFieldValue("email", [...Array.from(emailSet.add(value))]);
						}}
						components={{ DropdownIndicator: null }}
						noOptionsMessage={() => null}
					/>
					{!selectedFellowship && (
						<CustomSelect
							label="Select program"
							name="fellowship"
							placeholder="Select program"
							className="w-full border-none"
							searchable
							loadOptions={loadOptions}
							onChange={(value) => setFieldValue("fellowship", value.value)}
						/>
					)}
					<div className="flex items-center justify-end">
						<button className="cancel-btn" type="button" onClick={() => setIsOpen(false)}>
							Cancel
						</button>
						<button className="py-4 primary-btn btn-loader" disabled={isLoading}>
							Invite
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default InviteJudge;
