import { format, parseISO, set } from "date-fns";
import { ErrorMessage, useFormikContext } from "formik";
import React from "react";
import ReactDatePicker from "react-datepicker";
import { CheckIcon } from "src/assets/svg";

const QuestionInput = ({ section, question }) => {
	const label = question?.title;
	const name = `${section.id}||${question.id}||${question.title?.replaceAll(" ", "-")}`;

	return (
		<div className="grid min-[550px]:gap-6 min-[550px]:grid-cols-[250px,1fr]">
			<label className="mb-3 leading-[1.1] flex flex-col gap-1 font-medium text-[1.6rem] text-bodyText2">
				{label}
				<RequiredRender question={question} />
				<LabelRender type={question?.form_field_type} />
			</label>
			<div>
				<Render name={name} question={question} />
				<ErrorMessage name={name} component="div" className="block mt-2 text-[1.2rem] text-red-500" />
			</div>
		</div>
	);
};

const LabelRender = ({ type }) => {
	if (type === "multiple_choice") return <span className="text-[1.25rem] text-red-500">(Select only one)</span>;
	if (type === "checkboxes") return <span className="text-[1.25rem] text-red-500">(Select multiple)</span>;

	return null;
};

const RequiredRender = ({ question }) => {
	return !!question?.is_required && <span className="text-red-500 mt-1 text-[1.2rem]">Required</span>;
};

const Render = ({ question, name }) => {
	const { values, handleChange, setFieldValue } = useFormikContext();

	const type = question?.form_field_type;

	if (type === "dropdown")
		return (
			<select
				className="border rounded-md border-lightGray px-8 py-4 w-full focus:border-primary min-w-[250px] focus:![--tw-ring-shadow:transparent] !outline-0 placeholder:text-bodyText text-bodyText text-[1.6rem]"
				value={values[name]}
				onChange={handleChange}
				name={name}
			>
				<option value="" disabled>
					Select
				</option>
				{question?.options?.map((option, index) => (
					<option key={index} value={option}>
						{option}
					</option>
				))}
			</select>
		);

	if (type === "multiple_choice")
		return (
			<div className="flex flex-wrap gap-4">
				{question?.options?.map((option) => (
					<label
						className="text-[1.4rem] flex items-center gap-3 rounded-full has-[:checked]:bg-primary has-[:checked]:text-white font-medium text-bodyText px-8 py-4 bg-[#EFEFEF]"
						htmlFor={`${name}-${option}`}
					>
						<input type="radio" value={option} className="hidden" onChange={handleChange} name={name} id={`${name}-${option}`} /> {option}{" "}
						{values[name] === option && <CheckIcon />}
					</label>
				))}
			</div>
		);

	if (type === "checkboxes")
		return (
			<div className="flex flex-wrap gap-4">
				{question?.options?.map((option) => (
					<label
						className="text-[1.4rem] flex items-center gap-3 rounded-full has-[:checked]:bg-primary has-[:checked]:text-white font-medium text-bodyText px-8 py-4 bg-[#EFEFEF]"
						htmlFor={`${name}-${option}`}
					>
						<input type="checkbox" value={option} className="hidden" onChange={handleChange} name={name} id={`${name}-${option}`} />{" "}
						{option} {values[name]?.includes(option) && <CheckIcon />}
					</label>
				))}
			</div>
		);

	if (type === "date")
		return (
			<div className="flex gap-3 has-[:focus]:border-primary justify-between border border-lightGray rounded-md items-center px-8 py-4">
				<ReactDatePicker
					dateFormat="dd MMMM YYYY"
					showYearDropdown
					showMonthDropdown
					dropdownMode="scroll"
					className={`flex items-center gap-4 border-0 text-[1.6rem] p-0 has-[:disabled]:bg-[#FBFBFB] min-w-[250px] focus:![--tw-ring-shadow:transparent] !outline-0 placeholder:text-bodyText text-bodyText w-full`}
					selected={!!values[name] ? parseISO(values[name]) : new Date()}
					onChange={(date) => setFieldValue(name, format(date, "yyyy-MM-dd"))}
				/>
			</div>
		);

	if (type === "file_upload")
		return (
			<input
				type="file"
				name={name}
				className="w-full px-8 py-4 border file:rounded-full file:!bg-primary border-lightGray"
				onChange={(event) => setFieldValue(name, event.target.files[0])}
			/>
		);

	if (type === "time") {
		const [hours, minutes] = values[name].split(":").map(Number);
		return (
			<div className="flex gap-3 has-[:focus]:border-primary justify-between border border-lightGray rounded-md items-center px-8 py-4">
				<ReactDatePicker
					showTimeSelect
					showTimeSelectOnly
					timeIntervals={15}
					timeCaption="Time"
					dateFormat="h:mm aa"
					className={`flex items-center gap-4 border-0 text-[1.6rem] p-0 has-[:disabled]:bg-[#FBFBFB] min-w-[250px] focus:![--tw-ring-shadow:transparent] !outline-0 placeholder:text-bodyText text-bodyText w-full`}
					selected={!!values[name] ? set(new Date(), { hours, minutes, seconds: 0, milliseconds: 0 }) : new Date()}
					onChange={(date) => setFieldValue(name, format(date, "hh:mm"))}
				/>
			</div>
		);
	}
	if (type === "paragraph")
		return (
			<textarea
				rows="5"
				className={`border rounded-md w-full border-lightGray focus:![--tw-ring-shadow:transparent] placeholder:text-bodyText focus:border-primary px-8 py-4 text-[1.6rem] placeholder:text-[1.6rem] text-bodyText`}
				value={values[name]}
				onChange={handleChange}
				name={name}
			></textarea>
		);

	return (
		<div
			className={`flex items-center gap-4 border rounded-md border-lightGray px-8 py-4 text-[1.6rem] has-[:disabled]:bg-[#FBFBFB] has-[:focus]:border-primary min-w-[250px]`}
		>
			<input
				value={values[name]}
				onChange={handleChange}
				name={name}
				className={`!border-0 focus:![--tw-ring-shadow:transparent] !outline-0 placeholder:text-bodyText text-bodyText text-[1.6rem] w-full p-0`}
			/>
		</div>
	);
};

export default QuestionInput;
