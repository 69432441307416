import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { ErrorMessage, Formik } from "formik";
import React, { useState } from "react";
import { creatorFellowshipDetails, deleteMilestone as deleteMilestoneEndpoint, editEngagementTerms, engageApplicant } from "src/api";
import { AddIcon, CloseIcon, WarningIcon } from "src/assets/svg";
import { CustomDatePicker, CustomDurationPicker, CustomInput, CustomTextArea, Milestone } from "src/components";
import { useModalContext } from "src/components/Modal";
import { renderConfirmDialogue, renderCurrency, renderSuccessMessage } from "src/utilities/functions";
import * as Yup from "yup";

const milestoneExample = { description: "", amount: "", due_date: "" };

const EngageApplicant = ({ fellowship, applicants }) => {
	const { setIsOpen } = useModalContext();
	const queryClient = useQueryClient();

	const { data } = useQuery({
		queryKey: ["creator-fellowship", fellowship],
		queryFn: () => creatorFellowshipDetails(fellowship),
		suspense: true,
	});

	const fellowshipDetails = data?.data?.fellowship;

	const { mutate, isLoading } = useMutation(engageApplicant, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(async () => {
				await queryClient.refetchQueries({ queryKey: ["fellowship-selected-applicants", fellowship], type: "all" });
				setIsOpen(false);
			}),
	});

	const initialValues = {
		fellowship,
		applicants: applicants?.map((applicant) => applicant?.id),
		scope_of_work: "",
		project_duration: "",
		additional_terms: "",
		start_date: "",
		milestones: null,
		payment_type: "one-time",
		fixed_price: "",
		applicant_details: {
			role: "",
		},
	};
	const validationSchema = Yup.object().shape({
		applicants: Yup.array()
			.of(Yup.number().required("Applicant id is required"))
			.min(0, "At least one applicant is required")
			.required("Applicants is required"),
		scope_of_work: Yup.string().required("This field is required"),
		start_date: Yup.string().required("This field is required"),
		project_duration: Yup.string().required("This field is required"),
		additional_terms: Yup.string(),
		milestones: Yup.array()
			.of(
				Yup.object().shape({
					description: Yup.string().required("This field is required"),
					amount: Yup.number()
						.min(0, "Minimum allowable amount is zero")
						.required("This field is required")
						.typeError("This field must be a number"),
					due_date: Yup.string().required("This field is required"),
				})
			)
			.when("payment_frequency", {
				is: (value) => value === "milestone",
				then: (schema) => schema.required("Milestones are required").min(1, "At least 1 milestone is required"),
				otherwise: (schema) => schema.nullable(),
			}),
		payment_type: Yup.string()
			.oneOf(["milestone", "one-time"], "Payment Frequency must be one of Milestone or One Time")
			.required("This field is required"),
		fixed_price: Yup.number().when("payment_type", {
			is: (value) => value === "one-time",
			then: (schema) => schema.required("This field is required").min(0, "Minimum amount is 0"),
			otherwise: (schema) => schema.nullable(),
		}),
		applicant_details: Yup.object().shape({
			role: Yup.string().required("This field is required"),
		}),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				const { start_date, milestones } = values;
				const data = {
					...values,
					start_date: format(start_date, "yyyy-MM-dd"),
					total_amount: milestones?.reduce((sum, current) => sum + parseFloat(current.amount || 0), 0),
				};
				renderConfirmDialogue(
					"Are you sure?",
					`Are you sure you want to engage the applicant(s) - ${applicants
						?.map((applicant) => `${applicant?.first_name} ${applicant?.last_name}`)
						.join(", ")}?`
				).then((result) => {
					if (result) mutate(data);
				});
			}}
		>
			{({ handleChange, handleSubmit, values, setFieldValue }) => (
				<form onSubmit={handleSubmit} className="grid gap-6">
					<RenderAlert />
					<h3 className="text-[2rem] mb-6 text-headerText font-semibold font-Roobert">Project Overview</h3>
					<CustomTextArea
						name="scope_of_work"
						onChange={handleChange}
						value={values.scope_of_work}
						placeholder="Enter scope of work here"
						rows={6}
						className="py-6 placeholder:text-[1.4rem] !text-[1.4rem]"
						label="Scope of work"
					/>
					<div className="grid min-[600px]:grid-cols-2 gap-6">
						<CustomDurationPicker
							name="project_duration"
							value={values.project_duration}
							label="Project Duration"
							labelClassName="!mb-4 !block !font-medium !text-[1.6rem] !text-bodyText2"
						/>
						<CustomInput label="Budget" className="!text-[1.4rem]" value={renderCurrency(fellowshipDetails.project_budget)} disabled />
					</div>
					<div className="">
						<label className={`mb-3 block font-medium text-[1.6rem] text-bodyText2`}>Payment Type</label>
						<div className="grid min-[600px]:grid-cols-2 gap-6">
							<label
								htmlFor="one-time"
								className="has-[:checked]:text-primary flex items-center text-bodyText text-[1.4rem] justify-between has-[:checked]:border-primary border border-[#919BAF66] px-8 py-5 rounded-md"
							>
								One Time
								<input
									value="one-time"
									id="one-time"
									name="payment_type"
									checked={values.payment_type === "one-time"}
									onChange={(event) => {
										handleChange(event);
										setFieldValue("milestones", null);
										setFieldValue("fixed_price", "0");
									}}
									type="radio"
									className="accent-primary w-[17px] h-[17px] checked:bg-primary"
								/>
							</label>
							<label
								htmlFor="milestone"
								className="has-[:checked]:text-primary flex items-center text-bodyText text-[1.4rem] justify-between has-[:checked]:border-primary border border-[#919BAF66] px-8 py-5 rounded-md"
							>
								Milestone
								<input
									value="milestone"
									id="milestone"
									name="payment_type"
									checked={values.payment_type === "milestone"}
									onChange={(event) => {
										handleChange(event);
										setFieldValue("milestones", [milestoneExample]);
										setFieldValue("fixed_price", "");
									}}
									type="radio"
									className="accent-primary w-[17px] h-[17px] checked:bg-primary"
								/>
							</label>
						</div>
					</div>
					<div className="grid min-[600px]:grid-cols-2 gap-6 mb-10">
						<CustomDatePicker
							label="Start Date"
							name="start_date"
							selected={values.start_date}
							onChange={(date) => setFieldValue("start_date", date)}
							labelClassName={`!mb-3 !block !font-medium !text-[1.6rem] !text-bodyText2`}
						/>
					</div>
					{values.payment_type === "milestone" ? (
						<div>
							<p className="text-[1.4rem] text-[#101928] font-bold mb-6">Input milestones</p>
							<div className="grid gap-8 mb-16">
								{values.milestones.map((milestone, index) => (
									<Milestone milestone={milestone} key={index} index={index} />
								))}
								<button
									onClick={() => setFieldValue("milestones", [...values.milestones, { ...milestoneExample }])}
									type="button"
									className="text-primary text-[1.6rem] font-medium w-fit flex gap-4 items-center mt-6"
								>
									<AddIcon /> Add Milestone
								</button>
							</div>
							<div className="flex items-center justify-between py-[2.5rem] border-y border-[#919BAF66]">
								<div>
									<p className="text-[#101928] text-[1.8rem] font-bold">Total:</p>
									<p className="text-[#818B9C] text-[1.4rem]">
										This is the price the program creator will see. It includes all the milestones together.
									</p>
								</div>
								<p className="text-primary font-semibold text-[1.6rem]">
									{renderCurrency(values.milestones?.reduce((sum, current) => sum + parseFloat(current.amount || 0), 0))}
								</p>
							</div>
						</div>
					) : (
						<CustomInput
							label="Total payment"
							value={values.fixed_price}
							onChange={handleChange}
							placeholder={renderCurrency(1000000)}
							name="fixed_price"
							type="text"
							inputMode="numeric"
						/>
					)}
					<p className="font-bold text-[1.8rem] text-headerText mt-12 mb-2">Applicant Details</p>
					<CustomInput
						label="Role"
						value={values.applicant_details.role}
						onChange={handleChange}
						name="applicant_details.role"
						type="text"
					/>
					<p className="font-bold text-[1.8rem] text-headerText my-12">Terms</p>
					<CustomTextArea
						name="additional_terms"
						onChange={handleChange}
						value={values.additional_terms}
						placeholder="Enter any additional terms here"
						rows={6}
						className="py-6 placeholder:text-[1.4rem] !text-[1.4rem]"
						label="Additional terms for your project"
					/>
					<ErrorMessage name="applicants" component="div" className="block mt-2 text-[1.2rem] text-red-500" />
					<div className="flex items-center justify-end mt-14">
						<button className="cancel-btn" type="button" onClick={() => setIsOpen(false)}>
							Cancel
						</button>
						<button className="px-6 py-4 primary-btn btn-loader" disabled={isLoading}>
							Submit Engagement
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

const RenderAlert = () => {
	const [show, setShow] = useState(true);

	if (!show) return null;

	return (
		<div className="bg-[#FFF7F6] md:mb-10 items-start flex gap-5 justify-between p-10 rounded-2xl">
			<p className="text-bodyText2 text-[1.4rem] *:inline">
				<WarningIcon className="mr-3 -mt-2 text-primary" /> This is recognised as a contract between you and the applicant(s), containing your
				agreements for this project
			</p>
			<button type="button" onClick={() => setShow(false)}>
				<CloseIcon className="text-bodyText2" />
			</button>
		</div>
	);
};

export const EditEngageApplicant = ({ engagement, fellowship }) => {
	const { setIsOpen } = useModalContext();
	const queryClient = useQueryClient();

	const initialValues = {
		engagement: engagement?.uuid,
		fellowship,
		scope_of_work: engagement?.scope_of_work,
		project_duration: engagement?.project_duration,
		additional_terms: engagement?.additional_terms ?? "",
		start_date: new Date(engagement?.start_date),
		milestones: engagement?.milestones,
		payment_type: engagement?.payment_type,
		fixed_price: engagement?.fixed_price,
		applicant_details: {
			role: engagement?.applicant_details?.role,
		},
	};
	const validationSchema = Yup.object().shape({
		scope_of_work: Yup.string().required("This field is required"),
		start_date: Yup.string().required("This field is required"),
		project_duration: Yup.string().required("This field is required"),
		additional_terms: Yup.string(),
		milestones: Yup.array()
			.of(
				Yup.object().shape({
					description: Yup.string().required("This field is required"),
					amount: Yup.number()
						.min(0, "Minimum allowable amount is zero")
						.required("This field is required")
						.typeError("This field must be a number"),
					due_date: Yup.string().required("This field is required"),
				})
			)
			.when("payment_frequency", {
				is: (value) => value === "milestone",
				then: (schema) => schema.required("Milestones are required").min(1, "At least 1 milestone is required"),
				otherwise: (schema) => schema.nullable(),
			}),
		payment_type: Yup.string()
			.oneOf(["milestone", "one-time"], "Payment Frequency must be one of Milestone or One Time")
			.required("This field is required"),
		fixed_price: Yup.number().when("payment_type", {
			is: (value) => value === "one-time",
			then: (schema) => schema.required("This field is required").min(0, "Minimum amount is 0"),
			otherwise: (schema) => schema.nullable(),
		}),
		applicant_details: Yup.object().shape({
			role: Yup.string().required("This field is required"),
		}),
	});

	const { mutate, isLoading } = useMutation(editEngagementTerms, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(async () => {
				await queryClient.refetchQueries({ queryKey: ["fellowship-engaged-applicants", fellowship], type: "all" });
				setIsOpen(false);
			}),
	});

	const { mutate: deleteMutate } = useMutation(deleteMilestoneEndpoint);

	const deleteMilestone = (milestone) => deleteMutate({ engagement: engagement?.uuid, fellowship, milestone });

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				const { start_date, milestones } = values;
				const data = {
					...values,
					start_date: format(start_date, "yyyy-MM-dd"),
					total_amount: milestones?.reduce((sum, current) => sum + parseFloat(current.amount || 0), 0),
				};
				renderConfirmDialogue("Are you sure?", `Are you sure you want to edit the terms of the engagement?`).then((result) => {
					if (result) mutate(data);
				});
			}}
		>
			{({ handleChange, handleSubmit, values, setFieldValue }) => (
				<form onSubmit={handleSubmit} className="grid gap-6">
					<RenderAlert />
					<h3 className="text-[2rem] mb-6 text-headerText font-semibold font-Roobert">Project Overview</h3>
					<CustomTextArea
						name="scope_of_work"
						onChange={handleChange}
						value={values.scope_of_work}
						placeholder="Enter scope of work here"
						rows={6}
						className="py-6 placeholder:text-[1.4rem] !text-[1.4rem]"
						label="Scope of work"
					/>
					<div className="grid min-[600px]:grid-cols-2 gap-6">
						<CustomDurationPicker
							name="project_duration"
							value={values.project_duration}
							label="Project Duration"
							labelClassName="!mb-4 !block !font-medium !text-[1.6rem] !text-bodyText2"
						/>
						<CustomInput label="Budget" className="!text-[1.4rem]" value={renderCurrency(fellowship?.project_budget)} disabled />
					</div>
					<div className="">
						<label className={`mb-3 block font-medium text-[1.6rem] text-bodyText2`}>Payment Type</label>
						<div className="grid min-[600px]:grid-cols-2 gap-6">
							<label
								htmlFor="one-time"
								className="has-[:checked]:text-primary flex items-center text-bodyText text-[1.4rem] justify-between has-[:checked]:border-primary border border-[#919BAF66] px-8 py-5 rounded-md"
							>
								One Time
								<input
									value="one-time"
									id="one-time"
									name="payment_type"
									checked={values.payment_type === "one-time"}
									onChange={(event) => {
										handleChange(event);
										setFieldValue("milestones", null);
										setFieldValue("fixed_price", "0");
									}}
									type="radio"
									className="accent-primary w-[17px] h-[17px] checked:bg-primary"
								/>
							</label>
							<label
								htmlFor="milestone"
								className="has-[:checked]:text-primary flex items-center text-bodyText text-[1.4rem] justify-between has-[:checked]:border-primary border border-[#919BAF66] px-8 py-5 rounded-md"
							>
								Milestone
								<input
									value="milestone"
									id="milestone"
									name="payment_type"
									checked={values.payment_type === "milestone"}
									onChange={(event) => {
										handleChange(event);
										setFieldValue("milestones", [milestoneExample]);
										setFieldValue("fixed_price", "");
									}}
									type="radio"
									className="accent-primary w-[17px] h-[17px] checked:bg-primary"
								/>
							</label>
						</div>
					</div>
					<div className="grid min-[600px]:grid-cols-2 gap-6 mb-10">
						<CustomDatePicker
							label="Start Date"
							name="start_date"
							selected={values.start_date}
							onChange={(date) => setFieldValue("start_date", date)}
							labelClassName={`!mb-3 !block !font-medium !text-[1.6rem] !text-bodyText2`}
						/>
					</div>
					{values.payment_type === "milestone" ? (
						<div>
							<p className="text-[1.4rem] text-[#101928] font-bold mb-6">Input milestones</p>
							<div className="grid gap-8 mb-16">
								{values.milestones.map((milestone, index) => (
									<Milestone milestone={milestone} onClick={() => deleteMilestone(milestone?.uuid)} key={index} index={index} />
								))}
								<button
									onClick={() => setFieldValue("milestones", [...values.milestones, { ...milestoneExample }])}
									type="button"
									className="text-primary text-[1.6rem] font-medium w-fit flex gap-4 items-center mt-6"
								>
									<AddIcon /> Add Milestone
								</button>
							</div>
							<div className="flex items-center justify-between py-[2.5rem] border-y border-[#919BAF66]">
								<div>
									<p className="text-[#101928] text-[1.8rem] font-bold">Total:</p>
									<p className="text-[#818B9C] text-[1.4rem]">
										This is the price the program creator will see. It includes all the milestones together.
									</p>
								</div>
								<p className="text-primary font-semibold text-[1.6rem]">
									{renderCurrency(values.milestones?.reduce((sum, current) => sum + parseFloat(current.amount || 0), 0))}
								</p>
							</div>
						</div>
					) : (
						<CustomInput
							label="Total payment"
							value={values.fixed_price}
							onChange={handleChange}
							placeholder={renderCurrency(1000000)}
							name="fixed_price"
							type="text"
							inputMode="numeric"
						/>
					)}
					<p className="font-bold text-[1.8rem] text-headerText mt-12 mb-2">Applicant Details</p>
					<CustomInput
						label="Role"
						value={values.applicant_details.role}
						onChange={handleChange}
						name="applicant_details.role"
						type="text"
					/>
					<p className="font-bold text-[1.8rem] text-headerText my-12">Terms</p>
					<CustomTextArea
						name="additional_terms"
						onChange={handleChange}
						value={values.additional_terms}
						placeholder="Enter any additional terms here"
						rows={6}
						className="py-6 placeholder:text-[1.4rem] !text-[1.4rem]"
						label="Additional terms for your project"
					/>
					<div className="flex items-center justify-end mt-14">
						<button className="cancel-btn" type="button" onClick={() => setIsOpen(false)}>
							Cancel
						</button>
						<button className="px-6 py-4 primary-btn btn-loader" disabled={isLoading}>
							Edit Engagement
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default EngageApplicant;
