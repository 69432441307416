import { ErrorMessage } from "formik";
import React from "react";
import ReactSelect from "react-select/async";
import CreatableSelect from "react-select/async-creatable";

const classNames = {
	control: () => "!border-0",
	indicatorSeparator: () => "!hidden",
	placeholder: () => "!text-[1.4rem] !text-[#a8b0bd] capitalize",
	input: () => "!text-[1.4rem] !text-bodyText *:focus:![--tw-ring-shadow:transparent)] !font-medium",
	singleValue: () => "!text-[1.4rem] !text-bodyText !font-medium capitalize",
	menuList: () => "!text-[1.4rem] !text-bodyText !font-medium",
	valueContainer: () => "!py-[0.85rem] gap-5",
	multiValue: () => "!bg-[#FDF3F0] !text-primary",
	multiValueLabel: () => "!text-primary !text-[1.3rem] !font-medium",
};
const theme = (theme) => ({
	...theme,
	colors: {
		...theme.colors,
		primary: "var(--primary-color)",
		primary25: "var(--secondary-color)",
		primary50: "var(--secondary-color)",
	},
});

const CustomSelect = ({ options, label, placeholder, creatable, containerClassName, searchable, disablePlaceholder, ...rest }) => {
	if (creatable)
		return (
			<div>
				{!!label && <label className="mb-4 block font-medium text-[1.4rem] text-bodyText">{label}</label>}
				<div className={`${containerClassName} !border !border-lightGray !rounded-lg`}>
					<CreatableSelect isSearchable options={options} placeholder={placeholder} classNames={classNames} theme={theme} {...rest} />
				</div>
				{!!rest["name"] && <ErrorMessage name={rest["name"]} component="div" className="block mt-2 text-[1.2rem] text-red-500" />}
			</div>
		);
	if (searchable)
		return (
			<div>
				{!!label && <label className="mb-4 block font-medium text-[1.4rem] text-bodyText">{label}</label>}
				<div className={`${containerClassName} !border !border-lightGray !rounded-lg`}>
					<ReactSelect isSearchable options={options} placeholder={placeholder} classNames={classNames} theme={theme} {...rest} />
				</div>
				{!!rest["name"] && <ErrorMessage name={rest["name"]} component="div" className="block mt-2 text-[1.2rem] text-red-500" />}
			</div>
		);

	return (
		<div>
			{!!label && <label className="mb-4 block font-medium text-[1.4rem] text-bodyText">{label}</label>}
			<div className={`border border-lightGray rounded-lg ${containerClassName}`}>
				<select
					{...rest}
					className={`text-[1.4rem] focus:![--tw-ring-shadow:transparent)] pl-[14px] custom-select pr-[35px] py-[10px] text-bodyText ${rest.className}`}
				>
					<option value="" className="text-[#A0A8B6]" disabled={disablePlaceholder}>
						{placeholder}
					</option>
					{options?.map(({ value, label }, index) => (
						<option value={value} key={index}>
							{label}
						</option>
					))}
				</select>
			</div>
			{!!rest["name"] && <ErrorMessage name={rest["name"]} component="div" className="block mt-2 text-[1.2rem] text-red-500" />}
		</div>
	);
};

export default CustomSelect;
