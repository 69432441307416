import { useFormikContext } from "formik";
import { CustomDurationPicker, CustomInput } from ".";
import { CloseIcon } from "src/assets/svg";
import { removeItemAtIndex } from "src/utilities/functions";

const Milestone = ({ index, onClick = () => {} }) => {
	const { values, handleChange, setFieldValue } = useFormikContext();

	return (
		<div className="flex items-stretch justify-between gap-4 overflow-scroll">
			<p className="text-[1.4rem] text-black font-medium">{index + 1}</p>
			<CustomInput
				label="Description"
				value={values.milestones[index].description}
				onChange={handleChange}
				placeholder="Describe this milestone"
				name={`milestones[${index}].description`}
				innerContainerClassName="!px-6"
				className="!text-[1.4rem]"
				labelClassName="!text-[1.4rem]"
			/>
			<CustomDurationPicker name={`milestones[${index}].due_date`} value={values.milestones[index].due_date} label="Due Date" />
			<CustomInput
				label="Amount"
				type="number"
				min={0}
				value={values.milestones[index].amount}
				onChange={handleChange}
				placeholder="Amount"
				name={`milestones[${index}].amount`}
				innerContainerClassName="!px-6"
				className="!text-[1.4rem]"
				labelClassName="!text-[1.4rem]"
			/>
			<button
				type="button"
				className=""
				onClick={() => {
					setFieldValue("milestones", removeItemAtIndex(values.milestones, index));
					onClick();
				}}
			>
				<CloseIcon />
			</button>
		</div>
	);
};

export default Milestone;
