import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { verifyToken } from "src/api";
import { Loader } from "src/components";

const Verify = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const token = searchParams.get("token");

	useQuery({
		queryKey: ["verify-token", token],
		queryFn: () => verifyToken({ token }),
		onSuccess: ({ data }) => navigate("/finalise-register", { state: { email: data.email } }),
		onError: ({ data }) => navigate("/finalise-register", { state: { email: "data.email" } }),
		// onError: () => navigate("/login"),
		retry: 2,
	});

	return (
		<div className="grid grid-rows-[max-content,1fr] min-h-screen p-14">
			<h1 className="font-Roobert font-bold text-[2.81rem] text-[#1F1D1D] mb-20">Kohort</h1>
			<div className="mx-auto my-auto">
				<h1 className="text-headerText text-[2.6rem] sm:text-[4rem] font-bold leading-[1.1] text-center mb-6">Signing In</h1>
				<Loader />
			</div>
		</div>
	);
};

export default Verify;
