import React, { forwardRef, useRef, useState } from "react";
import { SendIcon } from "src/assets/svg";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { sendCreatorMessage, sendMessage } from "src/api";
import { useMessagingContext } from "src/context/MessagingContext";

const MessageRoomInput = ({ creator, messageListRef }) => {
	const { applicantID, fellowshipID, creatorID } = useMessagingContext();
	const queryClient = useQueryClient();
	const inputRef = useRef(null);

	const [message, setMessage] = useState("");

	const { mutate } = useMutation(creator ? sendCreatorMessage : sendMessage, {
		onMutate: () => {
			setMessage("");
			inputRef.current.focus();
			messageListRef.current?.scrollIntoView({ behavior: "smooth" });
		},
		onSettled: () => queryClient.invalidateQueries({ queryKey: [creator ? "creator-messages" : "messages"] }),
	});

	const send = () =>
		creator
			? mutate({ message, applicant_id: applicantID, fellowship_id: fellowshipID })
			: mutate({ message, creator_id: creatorID, fellowship_id: fellowshipID });

	const handleKeyDown = (event) => {
		if (event.key === "Enter" && !event.shiftKey) {
			event.preventDefault();
			send();
		}
	};

	return (
		<div className="sticky bottom-0 left-0 grid grid-cols-[1fr,max-content] items-end gap-5 w-full pt-10 bg-white">
			<CustomTextArea
				value={message}
				placeholder="Enter your message"
				onChange={(event) => setMessage(event.target.value)}
				rows={1}
				ref={inputRef}
				onKeyDown={handleKeyDown}
				className="min-h-[45px] leading-[1.5]"
			/>
			<button disabled={!message?.trim()} className="w-[45px] h-[45px] rounded-full bg-primary flex items-center justify-center" onClick={send}>
				<SendIcon className="w-[20px] h-[20px] text-white" />
			</button>
		</div>
	);
};

const CustomTextArea = forwardRef(({ label, containerClassName, className, name, ...rest }, ref) => {
	return (
		<div className={`grid gap-1 ${containerClassName}`}>
			<textarea
				rows="5"
				className={`border rounded-md border-lightGray focus:![--tw-ring-shadow:transparent] placeholder:text-bodyText focus:border-primary px-8 py-4 text-[1.6rem] placeholder:text-[1.6rem] text-bodyText ${className}`}
				name={name}
				ref={ref}
				{...rest}
			></textarea>
		</div>
	);
});

export default MessageRoomInput;
