import React, { useState } from "react";
import { useProSidebar } from "react-pro-sidebar";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { AddIcon, BurgerIcon, NotificationsIcon } from "src/assets/svg";
import { Modal } from "src/components";
import avatar from "src/assets/avatar.png";
import { CreateFellowship, Notifications } from "src/modals";
import { useAppContext } from "src/context/AppContext";
import { getInitials } from "src/utilities/functions";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";

const TopNav = () => {
	const { setUser, setToken, user } = useAppContext();
	const { pathname } = useLocation();

	const isCreatorRoute = pathname.startsWith("/creator");

	const { toggleSidebar } = useProSidebar();
	const isTabletOrSmaller = useMediaQuery({ query: "(max-width: 800px)" });

	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

	const logOut = () => {
		setToken("");
		setUser({});
	};

	return (
		<>
			<div className="px-8 py-[3rem] flex items-center justify-end">
				<div className="flex items-center gap-4">
					<button className="secondary-btn max-[1000px]:hidden" onClick={() => setIsCreateModalOpen(true)}>
						<AddIcon /> Create program
					</button>
					<Popover>
						<PopoverTrigger asChild>
							<button className="w-[48px] hover:bg-gray-200 aspect-square rounded-full flex items-center justify-center">
								<NotificationsIcon />
							</button>
						</PopoverTrigger>
						<PopoverContent className="bg-white">
							<Notifications />
						</PopoverContent>
					</Popover>
					<Popover>
						<PopoverTrigger asChild>
							{isTabletOrSmaller ? (
								<button className="bg-headerText w-[48px] aspect-square rounded-full text-[1.8rem] font-semibold text-white flex items-center justify-center">
									{getInitials(user?.first_name, user?.last_name)}
								</button>
							) : (
								<button className="grid grid-cols-[max-content,1fr] max-w-[220px] items-center gap-3 py-3">
									<div className="w-[40px] aspect-square rounded-full">
										<img src={avatar} alt="" />
									</div>
									<div className="text-left">
										<p className="text-headerText overflow-hidden max-w-[160px] font-medium truncate text-[1.5rem]">
											{user?.first_name} {user?.last_name}
										</p>
										<p className="text-bodyText text-[1.4rem] truncate">{user?.email}</p>
									</div>
								</button>
							)}
						</PopoverTrigger>
						<PopoverContent className="flex bg-white flex-col gap-2 max-w-[300px]">
							<div className="flex items-center gap-4 px-8 pt-6 pb-3">
								<div className="bg-headerText w-[48px] aspect-square rounded-full text-[1.8rem] font-semibold text-white flex items-center justify-center">
									{getInitials(user?.first_name, user?.last_name)}
								</div>
								<div>
									<p className="text-headerText text-[1.6rem] leading-[1.1] font-Roobert font-semibold">
										{user?.first_name} {user?.last_name}
									</p>
									<p className="text-bodyText2 text-[1.4rem] font-Roobert">{user?.email}</p>
								</div>
							</div>
							<ul className="flex flex-col gap-6 px-8 py-6 border-y border-lightGray">
								<li>
									<Link to="/creator/my-programs" className="hover:underline text-bodyText text-[1.6rem] font-medium">
										My programs
									</Link>
								</li>
								{isCreatorRoute && (
									<li>
										<Link to="messages" className="hover:underline text-bodyText text-[1.6rem] font-medium">
											Messages
										</Link>
									</li>
								)}
								<li>
									<Link to="/profile" className="hover:underline text-bodyText text-[1.6rem] font-medium">
										Edit profile
									</Link>
								</li>
								{isCreatorRoute ? (
									<>
										<li>
											<Link to="/my-programs" className="hover:underline text-bodyText text-[1.6rem] font-medium">
												Applicant Dashboard
											</Link>
										</li>
										<li>
											<Link to="/judge" className="hover:underline text-bodyText text-[1.6rem] font-medium">
												Judges Dashboard
											</Link>
										</li>
									</>
								) : (
									<>
										<li>
											<Link to="/my-programs" className="hover:underline text-bodyText text-[1.6rem] font-medium">
												Applicant Dashboard
											</Link>
										</li>
										<li>
											<Link to="/creator" className="hover:underline text-bodyText text-[1.6rem] font-medium">
												Creator Dashboard
											</Link>
										</li>
									</>
								)}
								<li>
									<button onClick={logOut} className="hover:underline text-bodyText text-[1.6rem] font-medium">
										Logout
									</button>
								</li>
							</ul>
						</PopoverContent>
					</Popover>
					<button className="p-4 rounded-full min-[1000px]:hidden" onClick={toggleSidebar}>
						<BurgerIcon />
					</button>
				</div>
			</div>
			<Modal isOpen={isCreateModalOpen} setIsOpen={setIsCreateModalOpen} title="Create a program">
				<CreateFellowship />
			</Modal>
		</>
	);
};

export default TopNav;
