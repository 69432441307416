import { useFormikContext } from "formik";
import React from "react";
import {
	CalendarIcon,
	CheckboxIcon,
	ClockIcon,
	CopyIcon,
	DeleteIcon,
	DropdownIcon,
	FileIcon,
	MultipleIcon,
	OptionsIcon,
	ParagraphIcon,
	ShortIcon,
} from "src/assets/svg";
import { CustomPopup } from "src/components";
import Switch from "react-switch";
import { PopupSelect2 } from "src/components/PopupSelect";
import { Input, appendToSectionQuestions } from ".";

const questionTypes = [
	{ value: 1, icon: <ShortIcon />, text: "Short answer" },
	{ value: 2, icon: <ParagraphIcon />, text: "Paragraph" },
	{ value: 3, icon: <DropdownIcon />, text: "Dropdown" },
	{ value: 4, icon: <MultipleIcon />, text: "Multiple Choice" },
	{ value: 5, icon: <CheckboxIcon />, text: "Checkboxes" },
	{ value: 6, icon: <CalendarIcon />, text: "Date" },
	{ value: 7, icon: <FileIcon />, text: "File Upload" },
	{ value: 8, icon: <ClockIcon />, text: "Time" },
];
const questionTypesObj = {
	1: "Short answer",
	2: "Paragraph",
	3: "Dropdown",
	4: "Multiple Choice",
	5: "Checkboxes",
	6: "Date",
	7: "File Upload",
	8: "Time",
};

function removeQuestionFromSectionAtIndex(sections, sectionIndex, questionIndex) {
	const section = sections[sectionIndex];

	const newQuestions = section.questions.slice(0, questionIndex).concat(section.questions.slice(questionIndex + 1));

	const newSections = sections.map((sec, idx) => {
		if (idx === sectionIndex) {
			return { ...sec, questions: newQuestions };
		}
		return sec;
	});

	return newSections;
}

const Question = ({ questionIndex, sectionIndex, question }) => {
	const { values, handleChange, setFieldValue } = useFormikContext();

	return (
		<div className="border-0 border-b pb-8 border-[#e6e9ec]">
			<div className="flex flex-wrap items-center justify-between gap-6 mb-3">
				<Input
					value={values.sections[sectionIndex].questions[questionIndex].title}
					onChange={handleChange}
					name={`sections[${sectionIndex}].questions[${questionIndex}].title`}
					className="text-[1.5rem] !w-full font-medium text-[#001439] p-0 !border-0 focus-within:!border-b focus:border-primary"
				/>
				<div className="flex items-center gap-4">
					<label className="flex items-center gap-2">
						<Switch
							onChange={(checked) => setFieldValue(`sections[${sectionIndex}].questions[${questionIndex}].is_required`, checked)}
							checked={values.sections[sectionIndex].questions[questionIndex].is_required}
							width={30}
							height={17}
							handleDiameter={12}
							uncheckedIcon={false}
							checkedIcon={false}
							onColor="#3B82F6"
						/>
						<span className="text-[1.2rem] font-medium text-bodyText2">Required</span>
					</label>
					<PopupSelect2
						value={values.sections[sectionIndex].questions[questionIndex].form_field_type_id}
						buttonClassName="!rounded-full !text-[1.2rem] font-medium !py-4 !px-6 gap-3"
						popupClassName="!min-w-[200px] !w-auto"
						placeholder="Program Type"
						display={questionTypesObj[values.sections[sectionIndex].questions[questionIndex].form_field_type_id]}
						options={questionTypes.map(({ icon, text, value }) => ({
							text: (
								<div className="flex items-center gap-4">
									{icon}
									{text}
								</div>
							),
							value: value,
							onClick: () => setFieldValue(`sections[${sectionIndex}].questions[${questionIndex}].form_field_type_id`, value),
						}))}
					/>
					<CustomPopup
						trigger={
							<button type="button" className="p-2 rounded-full text-bodyText hover:bg-gray-200 ">
								<OptionsIcon className="rotate-90" />
							</button>
						}
						keepTooltipInside=".modal-body"
						options={[
							{
								text: (
									<>
										<CopyIcon /> <p className="text-[1.4rem] font-medium">Duplicate</p>
									</>
								),
								onClick: () => setFieldValue("sections", appendToSectionQuestions(values.sections, sectionIndex, question)),
							},
							{
								text: (
									<>
										<DeleteIcon className="!text-red-500" />{" "}
										<p className="!text-red-500 text-[1.4rem] font-medium">Delete question</p>
									</>
								),
								onClick: () =>
									setFieldValue("sections", removeQuestionFromSectionAtIndex(values.sections, sectionIndex, questionIndex)),
							},
						]}
					/>
				</div>
			</div>
			<QuestionOptions question={question} questionIndex={questionIndex} sectionIndex={sectionIndex} />
		</div>
	);
};

const QuestionOptions = ({ question, questionIndex, sectionIndex }) => {
	const { values, handleChange } = useFormikContext();

	const type = questionTypesObj[question.form_field_type_id];

	const options = values.sections[sectionIndex].questions[questionIndex].options?.filter((value) => !!value);
	const optionsArray = Array((options?.length || 0) + 1).fill(" ");

	if (type === "Short answer") return null;

	if (type === "Paragraph") return null;

	if (type === "Date") return null;

	if (type === "Time") return null;

	if (type === "File Upload") return null;

	if (type === "Dropdown" || type === "Multiple Choice" || type === "Checkboxes")
		return (
			<div className="grid w-1/2 gap-4">
				{optionsArray.map((_, index) => {
					const option = options?.at(index);
					return (
						<div className="flex items-end gap-4" key={`section-${sectionIndex}-question-${questionIndex}-option-${index}`}>
							<span className="text-[1.4rem] font-medium text-[#ADB4C1]">{index + 1}</span>
							<input
								type="text"
								value={option}
								placeholder={`Option ${index + 1}`}
								onChange={handleChange}
								name={`sections[${sectionIndex}].questions[${questionIndex}].options[${index}]`}
								className="w-full border-0 border-b px-0 font-medium text-[1.4rem] border-[#ADB4C1] text-bodyText2"
								autoComplete="off"
							/>
						</div>
					);
				})}
			</div>
		);
};

export default Question;
