import React from "react";
import avatar from "src/assets/avatar.png";

const ParticipantCard = ({ user }) => {
	return (
		<div className="grid grid-cols-[max-content,1fr] items-center gap-3 py-5">
			<div className="w-[40px] aspect-square rounded-full">
				<img src={avatar} alt="" />
			</div>
			<div>
				<p className="text-headerText font-medium text-[1.5rem]">
					{user?.first_name} {user?.last_name}
				</p>
				<p className="text-bodyText text-[1.4rem]">{user?.email}</p>
			</div>
		</div>
	);
};

export default ParticipantCard;
