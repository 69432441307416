import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";

const { useFormikContext, ErrorMessage } = require("formik");
const { useState, useEffect } = require("react");
const { RightArrowHeadIcon } = require("src/assets/svg");
const { CustomSelect } = require(".");

const CustomDurationPicker = ({ value, name, labelClassName, label }) => {
	const { setFieldValue } = useFormikContext();

	const [number, setNumber] = useState("");
	const [duration, setDuration] = useState("");

	useEffect(() => {
		if (!!number && !!duration) setFieldValue(name, `${number} ${duration}`);
	}, [number, duration, setFieldValue, name]);

	return (
		<div>
			<Popover>
				<PopoverTrigger asChild>
					<div>
						<label className={`mb-3 block font-medium text-[1.4rem] text-bodyText2 ${labelClassName}`}>{label}</label>
						<button
							type="button"
							className="px-8 py-4 flex w-full justify-between items-center text-[1.4rem] border rounded-md border-lightGray min-w-[200px]"
						>
							<span className="text-bodyText min-h-[1.6rem]">{value}</span>
							<RightArrowHeadIcon className={"rotate-90"} />
						</button>
					</div>
				</PopoverTrigger>
				<PopoverContent className="flex gap-4 min-w-[200px] p-4">
					<CustomSelect
						label="Number"
						placeholder="Number"
						name="number"
						value={number}
						containerClassName="!rounded-lg !border !border-[#919BAF66]"
						className="!border-0"
						options={Array(12)
							.fill("")
							.map((_, index) => ({ value: index + 1, label: index + 1 }))}
						onChange={(event) => setNumber(event.target.value)}
					/>
					<CustomSelect
						label="Period"
						placeholder="Period"
						name="duration"
						disablePlaceholder
						value={duration}
						containerClassName="!rounded-lg !border !border-[#919BAF66]"
						className="!border-0"
						options={[
							{ label: "Day(s)", value: "day(s)" },
							{ label: "Weeks(s)", value: "week(s)" },
							{ label: "Months(s)", value: "month(s)" },
						]}
						onChange={(event) => setDuration(event.target.value)}
					/>
				</PopoverContent>
			</Popover>
			<ErrorMessage name={name} component="div" className="block mt-2 text-[1.2rem] text-red-500" />
		</div>
	);
};

export default CustomDurationPicker;
