import React from "react";
import styled, { keyframes } from "styled-components";

const Loader = (props) => {
	return (
		<div className="flex items-center justify-center" style={{ width: "100%", height: "10vh" }}>
			<Spinner {...props}></Spinner>
		</div>
	);
};

const loader = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const Spinner = styled.span`
	width: ${(props) => props.width || "30px"};
	height: ${(props) => props.width || "30px"};
	display: inline-block;
	padding: 0px;
	border-radius: 100%;
	border: 2px solid;
	border-top-color: rgba(253, 97, 75);
	border-bottom-color: rgba(253, 97, 75, 0.15);
	border-left-color: rgba(253, 97, 75);
	border-right-color: rgba(253, 97, 75, 0.15);
	-webkit-animation: ${loader} 0.8s linear infinite;
	animation: ${loader} 0.8s linear infinite;
`;

export default Loader;
