import { AppliedDisplay, NotAppliedDisplay } from ".";
import { useQuery } from "@tanstack/react-query";
import { getApplicantFellowship } from "src/api";
import { useParams } from "react-router-dom";

const LoggedInDetails = () => {
	const { id } = useParams();

	const { data } = useQuery({
		queryKey: ["fellowship", id],
		queryFn: () => getApplicantFellowship(id),
		suspense: true,
	});

	const hasApplied = data?.data?.hasApplied;
	const isJudgeOrCreator = data?.data?.isJudgeOrCreator;
	const isSelected = data?.data?.isSelected;
	const hasSubmittedProposal = data?.data?.hasSubmittedProposal;
	const hasBeenEngaged = data?.data?.hasBeenEngaged;
	const engagementStatus = data?.data?.engagementStatus;

	const fellowship = data?.data?.fellowship;
	const judges = data?.data?.myJudges;

	return hasApplied ? (
		<AppliedDisplay
			fellowship={fellowship}
			judges={judges}
			isSelected={isSelected}
			hasSubmittedProposal={hasSubmittedProposal}
			hasBeenEngaged={hasBeenEngaged}
			engagementStatus={engagementStatus}
		/>
	) : (
		<NotAppliedDisplay fellowship={fellowship} isJudgeOrCreator={isJudgeOrCreator} />
	);
};

export default LoggedInDetails;
