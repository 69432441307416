import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ErrorMessage, Formik, useFormikContext } from "formik";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import { submitMilestone } from "src/api";
import { AttachmentIcon, CloseIcon } from "src/assets/svg";
import { CustomTextArea } from "src/components";
import { useModalContext } from "src/components/Modal";
import { validFileExtensions } from "src/utilities/constants";
import { isValidFileType, renderSuccessMessage } from "src/utilities/functions";
import * as Yup from "yup";

const SubmitMilestone = ({ engagement, milestone }) => {
	const { id: fellowshipUUID } = useParams();
	const { setIsOpen } = useModalContext();
	const queryClient = useQueryClient();

	const initialValues = {
		fellowship: fellowshipUUID,
		engagement: engagement?.uuid,
		milestone: milestone?.uuid,
		content: "",
		file_upload: null,
	};
	const validationSchema = Yup.object().shape({
		content: Yup.string(),
		file_upload: Yup.mixed()
			.typeError("An attachment is required")
			.test("is-valid-type", `Not a valid file type, files must be either ${validFileExtensions.join(", ")}`, (value) => {
				return isValidFileType(value && value.name.toLowerCase());
			})
			.test("is-valid-size", "Max allowed size any file is 5 MB", (value) => {
				return value && value.size <= 10 * 1024 * 1024;
			})
			.nullable(),
	});

	const { mutate, isLoading } = useMutation(submitMilestone, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(() => {
				queryClient.refetchQueries({ queryKey: ["engagement", fellowshipUUID], type: "all" });
				setIsOpen();
			}),
	});

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="grid gap-6">
					<p className="text-[1.8rem] font-Roobert text-headerText font-semibold">Milestone: {milestone.description}</p>
					<CustomTextArea
						name="content"
						onChange={handleChange}
						value={values.content}
						placeholder="Enter any content"
						rows={6}
						className="py-6 placeholder:text-[1.4rem] !text-[1.4rem]"
						label="Content"
					/>
					<Dropzone name="file_upload" />
					<div className="flex items-center justify-end mt-5">
						<button className="cancel-btn" type="button" onClick={() => setIsOpen(false)}>
							Cancel
						</button>
						<button className="px-6 py-4 primary-btn btn-loader" disabled={isLoading}>
							Submit milestone
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

const Dropzone = ({ name }) => {
	const { setFieldValue, values } = useFormikContext();

	const onDrop = useCallback(
		(acceptedFiles) => {
			const files = acceptedFiles?.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }));
			setFieldValue(name, files?.at(0));
		},
		[name, setFieldValue]
	);

	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop,
		multiple: false,
		noClick: true,
		accept: {
			"text/*": [".csv", ".txt"],
			"application/msword": [".doc"],
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
			"application/vnd.ms-powerpoint": [".ppt"],
			"application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
			"application/pdf": [".pdf"],
			"application/vnd.ms-excel": [".xls"],
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
		},
	});

	return (
		<div>
			<p className="font-medium text-[1.6rem] text-bodyText2 mb-4">Attachments</p>
			<div className="border border-[919BAF1A] rounded-xl mb-2 px-16 py-16 flex items-center justify-center flex-col" {...getRootProps()}>
				<input {...getInputProps()} aria-label="input-file" />
				{!!values[name] && (
					<div className="flex flex-wrap justify-center gap-5 mb-8">
						<div className="text-[1.4rem] rounded-full font-medium text-bodyText px-3 py-1 bg-[#EFEFEF] flex items-center gap-3 z-50">
							<AttachmentIcon className="w-[15px] h-[15px]" />
							{values[name]?.name}
							<button
								className="p-1 rounded-md hover:bg-red-500 flex items-center justify-center hover:*:text-white"
								type="button"
								onClick={(event) => {
									event.stopPropagation();
									setFieldValue(name, null);
								}}
							>
								<CloseIcon className="w-[12px] h-[12px]" />
							</button>
						</div>
					</div>
				)}
				<p className="text-[1.6rem] text-bodyText font-semibold max-w-[280px] text-center mb-6 mt-3">
					Drag or{" "}
					<button type="button" onClick={open} className="underline text-primary">
						upload
					</button>{" "}
					files
				</p>
				<p className="text-[1.4rem] text-bodyText max-w-[410px] text-center mb-6">
					You may attach files under the size of 10MB each. This includes documents containing your proposal for this program and any other
					necessary documents.
				</p>
			</div>
			<ul className="flex items-center gap-x-8 gap-y-1 flex-wrap text-[1.4rem] list-inside list-disc text-bodyText">
				<li className="marker:!m-0">Maximum file size: 10MB</li>
				<li className="marker:!m-0">If you have multiple files, please zip them before uploading</li>
			</ul>
			<ErrorMessage name={name} component="div" className="block mt-2 text-[1.2rem] text-red-500" />
		</div>
	);
};

export default SubmitMilestone;
